import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Form,
  FormField,
  Header,
  Text,
  TextInput,
  Grommet,
  Tip,
  Anchor,
  Spinner,
} from "grommet";
import { Hide, View, CircleInformation } from "grommet-icons";
import { hpe } from "grommet-theme-hpe";
import { fetchDataAxios } from "../../Data/fetchData";
import { useHistory, useLocation } from "react-router";
import { oktaChangePasswordPayload } from "../../Helpers/requestsPayload";
import AlertBanner from "../AlertBanner";
import { constants } from "../../Helpers/content";
import { okta_change_password_endpoint } from "../../Helpers/endpoints";
import { success_component_path } from "../../Helpers/urls";
import { AuthContext } from "../../AuthContext";
import { ACTIONS } from "../../AuthReducer";
import Title from "../header/Title";
import { appendFromURI } from "../../Helpers/common";

const ChangePassword = () => {
  let history = useHistory();
  const location = useLocation();
  const [formValues, setFormValues] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const { stateToken } = location.state;
  const [credentialError, setCredentialError] = useState(false);
  const [message, setMessage] = useState("");
  const { state, dispatch } = useContext(AuthContext);
  const [currPwdValue, setCurrPwdValue] = useState("");
  const [currPwdReveal, setCurrPwdReveal] = useState(false);

  const [newPwdValue, setNewPwdValue] = useState("");
  const [newPwdReveal, setNewPwdReveal] = useState(false);

  const [confirmPwdValue, setConfirmPwdValue] = useState("");
  const [confirmPwdReveal, setConfirmPwdReveal] = useState(false);

  const [showSpinner, setShowSpinner] = useState(false);

  const {
    same_password_error,
    expire_password_nomatch,
    error,
    expire_pwd_title,
    curr_pwd_label,
    new_pwd_label,
    confirm_pwd_label,
    reset_pwd_btn,
    password_already_used_message,
    change_password_failed_message,
    required_field,
    login_title,
    cancel_btn,
    back_to_sign_in,
  } = state.content;

  const {
    password_validation_requirement,
    password_upper_case,
    password_lower_case,
    password_one_number,
    password_one_symbol,
    password_not_username,
    password_not_firstname,
    password_not_lastname,
    password_not_common_pwd,
    password_not_match_24pwd,
  } = state.password_validation;

  const {
    registration_Password_Rule_one_uppercase,
    registration_Password_Rules_one_lowercase,
    registration_Password_Rules_one_special,
    registration_Password_Rules_one_number,
    registration_Password_Rules_8_char,
  } = state.registration_Password_Rules;

  const passwordRulesStrong = [
    {
      regexp: new RegExp("(?=.*?[A-Z])"),
      message: registration_Password_Rule_one_uppercase,
      status: "error",
    },
    {
      regexp: new RegExp("(?=.*?[a-z])"),
      message: registration_Password_Rules_one_lowercase,
      status: "error",
    },
    {
      regexp: new RegExp("(?=.*?[#?!@$ %^&*-])"),
      message: registration_Password_Rules_one_special,
      status: "error",
    },
    {
      regexp: new RegExp("(?=.*?[0-9])"),
      message: registration_Password_Rules_one_number,
      status: "error",
    },
    {
      regexp: new RegExp(".{8,}"),
      message: registration_Password_Rules_8_char,
      status: "error",
    },
  ];

  const { SUCCESS, UPDATE_CREDENTIALS_FAILED } = constants;

  const onSubmit = async () => {
    if (currPwdValue === newPwdValue) {
      setCredentialError(true);
      setMessage(same_password_error);
      return;
    }
    setShowSpinner(true);
    setCredentialError(false);
    try {
      let cpResponse = await fetchDataAxios(
        okta_change_password_endpoint,
        oktaChangePasswordPayload(formValues, stateToken)
      );
      const cpResponseStatus = cpResponse?.status?.toUpperCase();
      setShowSpinner(false);
      if (cpResponseStatus === SUCCESS) {
        dispatch({ type: ACTIONS.successPassword });
        history.push(success_component_path);
      } else if (
        cpResponse?.errorSummary?.toUpperCase() === UPDATE_CREDENTIALS_FAILED
      ) {
        setCredentialError(true);
        setMessage(password_already_used_message);
      } else {
        setCredentialError(true);
        setMessage(change_password_failed_message);
      }
    } catch (err) {
      setShowSpinner(false);
      setCredentialError(true);
      if (err && err?.response?.status === 403)
        setMessage(password_already_used_message);
      else setMessage(change_password_failed_message);
    }
  };

  const cancelHandler = () => {
    history.push(appendFromURI());
  };

  const confirmPassword = () => {
    const doesMatch = formValues.newPassword === formValues.confirmPassword;
    return doesMatch
      ? undefined
      : { message: expire_password_nomatch, status: error };
  };

  return (
    <Grommet theme={hpe}>
      <Box
        flex
        gap="xsmall"
        width="medium"
        className="form-container"
        alignContent="center"
        margin={{ top: "large" }}
        pad={{ horizontal: "xxsmall" }}
      >
        <Title info={{ title: `HPE ${login_title}` }}></Title>
        <Header
          direction="column"
          align="start"
          gap="xxsmall"
          pad={{ horizontal: "xxsmall" }}
          className="form-header"
          style={{ paddingBottom: "0px" }}
        >
          <Box direction="row" align="center" margin={{ bottom: "0px" }}>
            <Text size="xlarge" weight="bold">
              {expire_pwd_title}
            </Text>
            <Tip
              content={
                <Box pad="small" gap="xxsmall">
                  <Text size="14px">{password_validation_requirement}</Text>
                  <Box style={{ fontSize: "14px", paddingLeft: "10px" }}>
                    <li>{password_upper_case}</li>{" "}
                    <li>{password_lower_case}</li>{" "}
                    <li>{password_one_number}</li>{" "}
                    <li>{password_one_symbol}</li>{" "}
                    <li>{password_not_username}</li>{" "}
                    <li>{password_not_firstname}</li>{" "}
                    <li>{password_not_lastname}</li>{" "}
                    <li>{password_not_common_pwd}</li>{" "}
                    <li>{password_not_match_24pwd}</li>{" "}
                  </Box>
                </Box>
              }
              dropProps={{ align: { left: "right" } }}
            >
              <Button
                a11yTitle="close"
                size="small"
                style={{ paddingBottom: "25px" }}
                icon={<CircleInformation color="rgba(23,235,160,1)" />}
              />
            </Tip>
          </Box>
        </Header>
        <Form
          validate="blur"
          value={formValues}
          onChange={setFormValues}
          messages={{
            required: required_field,
          }}
          onSubmit={() => onSubmit()}
          method="post"
        >
          {credentialError && <AlertBanner message={message} />}
          <FormField
            htmlFor="currentPassword"
            name="currentPassword"
            label={curr_pwd_label}
            required
          >
            <Box direction="row">
              <TextInput
                id="currentPassword"
                name="currentPassword"
                plain
                type={currPwdReveal ? "text" : "password"}
                value={currPwdValue}
                onChange={(event) => setCurrPwdValue(event.target.value)}
                className="custom-field"
              />
              <Button
                icon={
                  currPwdReveal ? (
                    <View size="medium" />
                  ) : (
                    <Hide size="medium" />
                  )
                }
                onClick={() => setCurrPwdReveal(!currPwdReveal)}
              />
            </Box>
          </FormField>
          <FormField
            htmlFor="newPassword"
            name="newPassword"
            label={new_pwd_label}
            validate={passwordRulesStrong}
            required
          >
            <Box direction="row">
              <TextInput
                id="newPassword"
                name="newPassword"
                plain
                type={newPwdReveal ? "text" : "password"}
                value={newPwdValue}
                onChange={(event) => setNewPwdValue(event.target.value)}
                className="custom-field"
              />
              <Button
                icon={
                  newPwdReveal ? <View size="medium" /> : <Hide size="medium" />
                }
                onClick={() => setNewPwdReveal(!newPwdReveal)}
              />
            </Box>
          </FormField>
          <FormField
            required
            htmlFor="confirmPassword"
            name="confirmPassword"
            label={confirm_pwd_label}
            validate={confirmPassword}
          >
            <Box direction="row">
              <TextInput
                id="confirmPassword"
                name="confirmPassword"
                plain
                type={confirmPwdReveal ? "text" : "password"}
                value={confirmPwdValue}
                onChange={(event) => setConfirmPwdValue(event.target.value)}
                className="custom-field"
              />
              <Button
                icon={
                  confirmPwdReveal ? (
                    <View size="medium" />
                  ) : (
                    <Hide size="medium" />
                  )
                }
                onClick={() => setConfirmPwdReveal(!confirmPwdReveal)}
              />
            </Box>
          </FormField>
          <Box
            align="start"
            margin={{ top: "25px", bottom: "small" }}
            fill="horizontal"
            direction="row"
            gap="small"
            justify="start"
          >
            <Button
              label={reset_pwd_btn}
              reverse
              primary
              type="submit"
              className="submit-btn"
              disabled={showSpinner ? true : false}
            />
            <Button
              label={cancel_btn}
              reverse
              secondary
              onClick={cancelHandler}
            />
          </Box>

          {showSpinner && <Spinner margin={{ left: "50px" }} />}

          <Box margin={{ top: "medium", bottom: "medium" }}>
            <Button onClick={cancelHandler} size="xxxsmall">
              <Anchor
                style={{ textDecoration: "none" }}
                color="#17EBA0"
                label={back_to_sign_in}
              />
            </Button>
          </Box>
        </Form>
      </Box>
    </Grommet>
  );
};

export default ChangePassword;
