import React, { useState, useContext } from "react";
import {
  Grommet,
  Box,
  Button,
  Header,
  Form,
  FormField,
  Text,
  TextInput,
  Tip,
  Anchor,
  Spinner,
} from "grommet";
import { hpe } from "grommet-theme-hpe";
import { useHistory } from "react-router";
import { CircleInformation } from "grommet-icons";
import { fetchData } from "../../Data/fetchData";
import { okta_forgot_password_endpoint } from "../../Helpers/endpoints";
import { send_email_path, enviroment } from "../../Helpers/urls";
import AlertBanner from "../AlertBanner";
import { AuthContext } from "../../AuthContext";
import { ACTIONS } from "../../AuthReducer";
import Title from "../header/Title";
import { oktaForgotPasswordPayload } from "../../Helpers/requestsPayload";
import { constants } from "../../Helpers/content";
import {
  dynamicHelpPage,
  forgotPasswordActivateRedirectCookieUpdate,
  replaceString,
  doubleEncoderUrl,
} from "../../Helpers/common";

const ForgotPassword = () => {
  const [formValues, setFormValues] = useState({ forgotPassword: "" });
  const [credentialError, setCredentialsError] = useState(false);
  const [message, setMessage] = useState("");
  const { state, dispatch } = useContext(AuthContext);
  const history = useHistory();
  const [showSpinner, setShowSpinner] = useState(false);

  const {
    system_error,
    forgot_pwd_title,
    userID_email_label,
    userID_email_ph,
    cancel_btn,
    password_reset_btn,
    required_field,
    login_title,
    get_help_label,
  } = state.content;
  const {
    password_validation_requirement,
    password_upper_case,
    password_lower_case,
    password_one_number,
    password_one_symbol,
    password_not_username,
    password_not_firstname,
    password_not_lastname,
    password_not_common_pwd,
    password_not_match_24pwd,
  } = state.password_validation;

  const { selectedLanguage, languageCode, supportedLanguagesListDetails } = state;
  const { RECOVERY_CHALLENGE } = constants;

  const usernameLength = [
    {
      regexp: new RegExp(".{5,}"),
      message: state.content.user_name_validation,
      status: "error",
    },
  ];

  const setApplicationContext = () => {
    const fromURIValue = localStorage.getItem("fromURI");
    if (fromURIValue) {
      const checkReplaceString = doubleEncoderUrl(
        replaceString(decodeURIComponent(fromURIValue), "&filter=false", "")
      );
      localStorage.setItem("activationRedirect", checkReplaceString);
      forgotPasswordActivateRedirectCookieUpdate(checkReplaceString);
    }
  };

  const onSubmit = () => fpHandler();

  const fpHandler = async () => {
    setShowSpinner(true);
    try {
      const fpResponse = await fetchData(
        okta_forgot_password_endpoint,
        oktaForgotPasswordPayload(formValues.forgotPassword)
      );
      setShowSpinner(false);
      const fpResponseStatus = fpResponse?.status?.toUpperCase();
      if (fpResponseStatus === RECOVERY_CHALLENGE) {
        dispatch({ type: ACTIONS.sendEmail });
        setApplicationContext();
        history.push(send_email_path);
      } else {
        setCredentialsError(true);
        setMessage(system_error);
      }
    } catch (err) {
      setShowSpinner(false);
      setCredentialsError(true);
      setMessage(system_error);
    }
  };

  const onLoginClick = () => history.goBack();

  return (
    <Grommet theme={hpe}>
      <Box
        flex
        gap="xsmall"
        width="medium"
        className="form-container"
        alignContent="center"
      >
        <Title info={{ title: `HPE ${login_title}` }}></Title>
        <Text
          size="large"
          weight="normal"
          style={{ paddingBottom: "17px", lineHeight: "20px" }}
        >
          {state.content.verify_email_text}
        </Text>
        <Header
          direction="column"
          align="start"
          gap="xxsmall"
          pad={{ horizontal: "xxsmall" }}
          className="form-header"
        >
          <Text size="xxlarge" weight="bold">
            <Box direction="row" align="center">
              <Text
                weight="bold"
                className={`forget-password-title-${languageCode}`}
              >
                {forgot_pwd_title}
              </Text>
              <Tip
                content={
                  <Box pad="small" gap="xxsmall">
                    <Text size="14px">{password_validation_requirement}</Text>
                    <Box style={{ fontSize: "14px", paddingLeft: "10px" }}>
                      <li>{password_upper_case}</li>{" "}
                      <li>{password_lower_case}</li>{" "}
                      <li>{password_one_number}</li>{" "}
                      <li>{password_one_symbol}</li>{" "}
                      <li>{password_not_username}</li>{" "}
                      <li>{password_not_firstname}</li>{" "}
                      <li>{password_not_lastname}</li>{" "}
                      <li>{password_not_common_pwd}</li>{" "}
                      <li>{password_not_match_24pwd}</li>{" "}
                    </Box>
                  </Box>
                }
                dropProps={{ align: { left: "right" } }}
              >
                <Button
                  a11yTitle="close"
                  size="small"
                  style={{ paddingBottom: "25px" }}
                  icon={<CircleInformation color="rgba(23,235,160,1)" />}
                />
              </Tip>
            </Box>
          </Text>
        </Header>

        <Box
          // Padding used to prevent focus from being cutoff
          pad={{ horizontal: "xxsmall" }}
        >
          <Form
            validate="blur"
            value={formValues}
            messages={{
              required: required_field,
            }}
            onChange={setFormValues}
            onSubmit={() => onSubmit()}
          >
            {credentialError && <AlertBanner message={message} />}
            <Box gap="small">
              <FormField
                label={userID_email_label}
                htmlFor="forgotPassword"
                name="forgotPassword"
                validate={usernameLength}
                required
              >
                <TextInput
                  id="forgotPassword"
                  name="forgotPassword"
                  placeholder={userID_email_ph}
                />
              </FormField>

              <Box
                align="start"
                margin={{ top: "0px", bottom: "small" }}
                fill="horizontal"
                direction="row"
                gap="small"
                justify="start"
              >
                <Button
                  label={password_reset_btn}
                  reverse
                  primary
                  type="submit"
                  className="submit-btn"
                  disabled={showSpinner ? true : false}
                />
                <Button
                  label={cancel_btn}
                  reverse
                  secondary
                  onClick={() => {
                    onLoginClick();
                  }}
                />
              </Box>

              {showSpinner && <Spinner margin={{ left: "50px" }} />}

              <Box style={{ paddingTop: "15px" }}>
                <Anchor
                  label={get_help_label}
                  target="_blank"
                  rel="noopener"
                  href={dynamicHelpPage(enviroment, supportedLanguagesListDetails, selectedLanguage)}
                />
              </Box>
            </Box>
          </Form>
        </Box>
      </Box>
    </Grommet>
  );
};

export default ForgotPassword;
