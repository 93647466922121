import React, { useContext } from "react";
import { Grommet, Box, Header, ResponsiveContext, Text, Button } from "grommet";
import { hpe } from "grommet-theme-hpe";
import { useHistory } from "react-router";
import { FormNext } from "grommet-icons";
import { AuthContext } from "../../AuthContext";
import { ACTIONS } from "../../AuthReducer";
import { appendFromURI } from "../../Helpers/common";

const SendEmail = () => {
  const size = useContext(ResponsiveContext);
  const history = useHistory();
  const { state, dispatch } = useContext(AuthContext);
  const { sent_email_title, sent_email_text, back_to_login_btn } =
    state.content;

  const onBacktoLogin = () => {
    dispatch({ type: ACTIONS.login });
    history.push(appendFromURI());
  };

  return (
    <Grommet theme={hpe}>
      <Box
        flex
        gap="medium"
        width="medium"
        className="form-container"
        alignContent="center"
      >
        <Header
          direction="column"
          align="start"
          gap="xxsmall"
          pad={{ horizontal: "xxsmall" }}
          className="form-header"
        >
          <Text weight="bold" style={{ fontSize: "32px", lineHeight: "40px" }}>
            {sent_email_title}
          </Text>
        </Header>

        <Text size="small" weight="normal">
          {sent_email_text}
        </Text>

        <Box
          align={size !== "small" ? "end" : undefined}
          margin={{ top: "medium", bottom: "small" }}
        >
          <Button
            label={back_to_login_btn}
            icon={<FormNext />}
            reverse
            primary
            onClick={() => onBacktoLogin()}
            className="submit-btn"
          />
        </Box>
      </Box>
    </Grommet>
  );
};

export default SendEmail;
