/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { detect } from "../../../node_modules/detect-browser";
import Cookies from "js-cookie";
import {
  Grommet,
  Box,
  Button,
  CheckBox,
  Form,
  FormField,
  Text,
  TextInput,
  Anchor,
  Spinner,
  Tip,
} from "grommet";
import { CircleInformation } from "grommet-icons";
import { useHistory, useLocation } from "react-router-dom";
import { getDataAxios, getValidateSIDFetchAxios, validateGtFlowAxios } from "../../Data/fetchData";
import {
  login_path,
  oktaAppHome,
  unlockUrl,
  webFingerUrl,
  hpeDomainLoginUrl,
  enviroment,
} from "../../Helpers/urls";
import { hpe } from "grommet-theme-hpe";
import AlertBanner from "../AlertBanner";
import { session_endpoint, gts_validate } from "../../Helpers/endpoints";
import { AuthContext } from "../../AuthContext";
import {
  checkUrlEncode,
  dynamicHelpPage,
  fromURITest,
  encodeURL,
  doubleDecoderUrl,
  replaceString,
  ieCompatibalityCheck,
  isAbsoluteURL,
} from "../../Helpers/common";
import Title from "../header/Title";

const Username = () => {
  const location = useLocation();
  const search = location.search;
  let login_hint = new URLSearchParams(search).get("login_hint");
  if (
    login_hint &&
    (typeof login_hint === "string" || login_hint instanceof String)
  ) {
    login_hint = decodeURIComponent(login_hint).trim();
  }
  const [email, setEmail] = useState(
    login_hint
      ? login_hint
      : localStorage.getItem("email")
      ? localStorage.getItem("email").trim()
      : ""
  );
  const [formValues, setFormValues] = useState({
    email: email,
  });
  const [credentialError, setCredentialError] = useState(false);
  const [message, setMessage] = useState("");
  const [showloader, setShowLoader] = useState(true);
  const [checked, setChecked] = useState(false);
  const [versionIE, setVersionIE] = useState(false);
  let history = useHistory();

  const { state } = useContext(AuthContext);
  const { isAppMounted, isIE } = state;

  const {
    selectedLanguage,
    supportedLanguagesListDetails = [],
  } = state;

  const usernameLength = [
    {
      regexp: new RegExp(".{5,}"),
      message: state.content.user_name_validation,
      status: "error",
    },
  ];

  const {
    login_title,
    user_id_length_error,
    required_field,
    userID_email_label,
    userID_email_ph,
    remember_me_label,
    forgotPassword,
    unlockAccount,
    signUp,
    ie_browser_message,
    next,
    dont_have_an_account,
    forget_user_id,
    email_update_with_user_id,
    get_help_label,
    unlock_account_tip_message_1,
    unlock_account_tip_message_2,
  } = state.content;

  let q = new URLSearchParams(search).get("fromURI");
  const fromURI = q;
  let relayState = q;
  if (relayState) {
    relayState = "?RelayState=" + encodeURL(relayState);
  } else {
    relayState = "";
  }
  q = q === null ? oktaAppHome : q;

  let redirectUri = new URLSearchParams(decodeURIComponent(search)).get(
    "redirect_uri"
  );

  if (!redirectUri)
    redirectUri = new URLSearchParams(doubleDecoderUrl(search)).get(
      "redirect_uri"
    );
    
  const hasClientId = search.includes("client_id");

  useEffect(() => {
    const fromURI = new URLSearchParams(search).get("fromURI");
    if (isAppMounted) validateSID(fromURI);
  }, [isAppMounted]);

  const validateSID = async (fromURI) => {
    const sid = Cookies.get("sid");
    setShowLoader(true);
    try {
      const data = await getValidateSIDFetchAxios(session_endpoint, sid);
      if (data && data.id) {
        const fromUri =
          fromURI === null
            ? oktaAppHome
            : isAbsoluteURL(fromURI)
            ? fromURI
            : hpeDomainLoginUrl + decodeURIComponent(fromURI);
        window.location = fromUri;
      } else setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      return err;
    }
  };

  useEffect(() => {
    const browser = detect();
    const[isIEcheck, getIEversion] = ieCompatibalityCheck();
    setVersionIE(getIEversion);

    if (localStorage.getItem("email")) {
      setFormValues({
        email: localStorage.getItem("email"),
      });
    }
    if (fromURI !== null) {
      localStorage.setItem("fromURI", checkUrlEncode(fromURI));
    } else {
      localStorage.setItem("fromURI", "");
    }
    if (login_hint) {
      if (login_hint.length >= 5) {
        setShowLoader(true);
        verifyUser();
      } else {
        setShowLoader(false);
        setCredentialError(true);
        setMessage(user_id_length_error);
      }
    }
    //eslint-disable-next-line
  }, []);

  const dispatchEventAnalyticsCheckIE = () =>{
    if(!isIE){
      window.dispatchEvent(
        new CustomEvent("ANALYTICS.LINK", {
          detail: {
            link_name: "iam:login-screen:next-button",
          },
        })
      );
    }
  }

  const onSubmit = async () => {
    verifyUser();
  };
  const verifyUser = async () => {
    const trimmedEmail = email.trim();
    dispatchEventAnalyticsCheckIE();
    if (trimmedEmail === "@hpe.com") {
      history.push({
        pathname: `${login_path}`,
        state: {
          username: trimmedEmail,
          fromUri: q,
          relayState: relayState,
          applicationContextFromUri: fromURI,
          hasClientId,
          redirectUri,
        },
      });
      return;
    }
    if (checked) localStorage.setItem("email", trimmedEmail);
    const encodedEmail = encodeURIComponent(trimmedEmail);
    const url = `${webFingerUrl}${encodedEmail}`;
    const data = await getDataAxios(url);
    if (data && data.links && data.links[0].rel === "okta:idp") {
      let redirectUrl = data.links[0].href;
      redirectUrl = redirectUrl.slice(0, -1);
      window.location = `${redirectUrl}&fromURI=${encodeURIComponent(
        replaceString(decodeURIComponent(q), "&filter=false", "")
      )}`;
    } else {
      history.push({
        pathname: `${login_path}`,
        state: {
          username: trimmedEmail,
          fromUri: q,
          relayState: relayState,
          applicationContextFromUri: fromURI,
          hasClientId,
          redirectUri,
        },
      });
      return;
    }
  };
  const onChange = (values) => {
    setFormValues(values);
  };

  const registrationHandler = () => {
    if (!relayState) {
      history.push('/registration');
      return;
    }
    history.push(`/registration${relayState}`);
  };

  const forgotPasswordHandler = () => history.push('/forgot-password');

  return (
    <Grommet theme={hpe}>
      {versionIE && (
        <Box
          align="center"
          justify="center"
          pad="large"
          height={{ min: "small" }}
        >
          <Text size="xxlarge" weight="bold">
            {ie_browser_message}
          </Text>
        </Box>
      )}
      {showloader && (
        <Box align="center" justify="center" height={{ min: "small" }}>
          <Spinner size="medium" />
        </Box>
      )}
      {!showloader && !versionIE && (
        <Box
          flex
          gap="small"
          width="medium"
          className="form-container"
          alignContent="center"
        >
          <Title info={{ title: `HPE ${login_title}` }} />
          <Box pad={{ horizontal: "xxsmall" }}>
            <Form
              validate="blur"
              value={formValues}
              onChange={(value) => onChange(value)}
              messages={{
                required: required_field,
              }}
              onSubmit={() => onSubmit()}
            >
              {credentialError && <AlertBanner message={message} />}
              <FormField
                required
                label={userID_email_label}
                name="email"
                htmlFor="email-sign-in"
                validate={usernameLength}
              >
                <TextInput
                  id="email-sign-in"
                  name="email"
                  placeholder={userID_email_ph}
                  className="custom-field"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormField>

              <div className="login-fields-wrapper">
                <div htmlFor="remember-me" id="remember-cb">
                  <CheckBox
                    id="remember-me"
                    name="rememberMe"
                    checked={checked}
                    label={remember_me_label}
                    onChange={(e) => setChecked(e.target.checked)}
                  />
                </div>
              </div>
              <Box
                align="start"
                margin={{ top: "small", bottom: "small" }}
                fill="horizontal"
                direction="row"
                gap="medium"
                justify="between"
              >
                <Button
                  label={next}
                  reverse
                  primary
                  size="medium"
                  type="submit"
                  className="submit-btn"
                  style={{ padding: "7px 50px" }}
                />
              </Box>
            </Form>
          </Box>
          {!isIE && (
          <Box gap="xxsmall">
            <Box direction="row" align="center">
                <Button onClick={forgotPasswordHandler} size="xxxsmall">
                  <u className="bold-font-500">{forgotPassword}</u>
                </Button>
            </Box>
            <Box direction="row" align="center">
              <Text weight={"500"}>{forget_user_id}</Text>
              <Tip
                content={
                  <Box pad="small" gap="xxsmall">
                    <Text size="14px">{email_update_with_user_id}</Text>
                  </Box>
                }
                dropProps={{ align: { left: "right" } }}
              >
                <Button
                  a11yTitle="close"
                  size="small"
                  icon={<CircleInformation color="rgba(23,235,160,1)" />}
                />
              </Tip>
            </Box>
            <Box direction="row" align="center">
              <Anchor
                label={unlockAccount}
                href={unlockUrl}
              />
              <Tip
                content={
                  <Box pad="small" gap="xxsmall">
                    <Text size="14px">{unlock_account_tip_message_1}</Text>
                    <Text size="14px">{unlock_account_tip_message_2}</Text>
                  </Box>
                }
                dropProps={{ align: { left: "right" } }}
              >
                <Button
                  a11yTitle="close"
                  size="small"
                  icon={<CircleInformation color="rgba(23,235,160,1)" />}
                />
              </Tip>
            </Box>
            <Box direction="row" gap="small">
              <Text>{dont_have_an_account}</Text>
              <div className="link-container">
                <Button onClick={registrationHandler} size="xxxsmall">
                  <u className="bold-font-500">{signUp}</u>
                </Button>
              </div>
            </Box>
            <Box>
              <Anchor
                label={get_help_label}
                target="_blank"
                rel="noopener"
                href={dynamicHelpPage(enviroment, supportedLanguagesListDetails, selectedLanguage)}
              />
            </Box>
          </Box>
          )}
        </Box>
      )}
    </Grommet>
  );
};

export default Username;
