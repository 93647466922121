import React, { useContext } from "react";
import { Grommet, Text, Box, Header } from "grommet";
import { hpe } from "grommet-theme-hpe";
import { contact_HPE_support } from "../../Helpers/urls";
import { AuthContext } from "../../AuthContext";
import { formatAnchorTag } from "../../Helpers/common";

const OOTBSiteDownPage = () => {
  const { state } = useContext(AuthContext);
  const {  contact_hpe_support_label } = state.content;
  const { error_title, error_subtitle, error_message } = state.complete_site_down;

  return (
    <Grommet theme={hpe}>
      <Box
        flex
        gap="medium"
        className="form-container"
        pad={"large"}
        margin="80px 0px"
      >
        <Header
          gap="xsmall"
          direction="column"
          className="form-header"
          style={{ textAlign: "center" }}
        >
          <Text size="xlarge" weight="bold">
            {error_title}
          </Text>
          <Text size="xlarge" weight="bold">
            {error_subtitle}
          </Text>
        </Header>
        <Box margin="20px 0px" align="center" style={{ textAlign: "center" }}>
          <Text size="medium">
            {formatAnchorTag(
              error_message,
              contact_hpe_support_label,
              contact_HPE_support
            )}
          </Text>
        </Box>
      </Box>
    </Grommet>
  );
};

export default OOTBSiteDownPage;
