import React, { useContext, useState } from "react";
import { Grommet, Box, Button, Header, Text } from "grommet";
import { hpe } from "grommet-theme-hpe";
import { FormNext } from "grommet-icons";
import { fetchData, fetchDataAxios } from "../../Data/fetchData";
import { useLocation, useHistory } from "react-router";
import { loginUpdatePayload } from "../../Helpers/requestsPayload";
import { constants } from "../../Helpers/content";
import { AuthContext } from "../../AuthContext";
import { ACTIONS } from "../../AuthReducer";
import {
  email_verification_success_path,
  update_email_path,
  contact_HPE_support,
} from "../../Helpers/urls";
import { login_update_endpoint } from "../../Helpers/endpoints";
import AlertBanner from "../AlertBanner";
import Title from "../header/Title";
import { formatAnchorTag } from "../../Helpers/common";

const EmailVerification = () => {
  const [credentialError, setCredentialError] = useState(false);
  const [message, setMessage] = useState("");
  let history = useHistory();
  let location = useLocation();
  const { state, dispatch } = useContext(AuthContext);
  const { email, userId, text_content } = location.state;
  const {
    exception,
    system_error,
    verify_email_title,
    email_verification_error,
    email_correct_question,
    no_update_email_btn,
    verify_email_btn,
    userID_label,
    email_id,
    login_title,
    contact_hpe_support_label
  } = state.content;
  const { success } = constants;

  const verifyEmail = async () => {
    const data = await fetchDataAxios(
      login_update_endpoint,
      loginUpdatePayload(email, location.state)
    );

    if (Object.keys(data).indexOf(exception) === -1) {
      if (
        data &&
        data.changeUserIdResponse &&
        data.changeUserIdResponse.status &&
        data.changeUserIdResponse.status === success
      ) {
        dispatch({ type: ACTIONS.emailverificationsuccess });
        history.push(email_verification_success_path);
        return;
      } else if (
        data &&
        data.changeEmailResponse &&
        data.changeEmailResponse.status &&
        data.changeEmailResponse.status === success
      ) {
        dispatch({ type: ACTIONS.emailverificationsuccess });
        history.push(email_verification_success_path);
        return;
      } else {
        setCredentialError(true);
        setMessage(formatContactHpeSupportLink(email_verification_error));
      }
    } else {
      setCredentialError(true);
      setMessage(system_error);
    }
  };

  const updateEmail = async () => {
    dispatch({ type: ACTIONS.updateEmail });
    history.push({ pathname: update_email_path, state: location.state });
    return;
  };

  const formatContactHpeSupportLink = (message) =>
    formatAnchorTag(message, contact_hpe_support_label, contact_HPE_support);

  return (
    <Grommet theme={hpe}>
      <Box
        flex
        gap="small"
        width="medium"
        className="form-container"
        alignContent="center"
      >
        <Title info={{ title: `HPE ${login_title}` }} />
        {credentialError && <AlertBanner message={message} />}
        <Text>{text_content}</Text>
        <Header
          direction="column"
          align="start"
          gap="xxsmall"
          pad={{ horizontal: "xxsmall" }}
          className="form-header"
        >
          <Text size="xlarge" weight="bold">
            {verify_email_title}
          </Text>
        </Header>
        <Box pad={{ horizontal: "xxsmall" }}>
          <Box align="end" fill="horizontal" direction="row">
            <Text size="medium" color="black" weight="bold">
              {userID_label}
            </Text>
          </Box>
          <Box align="end" fill="horizontal" direction="row">
            <Text className="default-values">{userId}</Text>
          </Box>
          <Box
            align="end"
            fill="horizontal"
            direction="row"
            className="default-labels"
          >
            <Text size="medium" color="black" weight="bold">
              {email_id}
            </Text>
          </Box>
          <Box align="end" fill="horizontal" direction="row">
            <Text className="default-values">{email}</Text>
          </Box>
          <Box
            align="end"
            fill="horizontal"
            direction="row"
            className="question-text"
          >
            <Text size="medium" color="black" weight="bold">
              {email_correct_question}
            </Text>
          </Box>
          <Box
            margin={{ top: "small", bottom: "small" }}
            fill="horizontal"
            direction="row"
            gap="small"
            // justify='center'
          >
            <Button
              label={verify_email_btn}
              // icon={<FormNext />}
              reverse
              primary
              onClick={verifyEmail}
              className="submit-btn verify"
              style={{ padding: "5px 20px" }}
            />
            <Button
              label={no_update_email_btn}
              // icon={<FormNext />}
              reverse
              secondary
              onClick={updateEmail}
              className="submit-btn update"
              style={{ padding: "5px 20px" }}
            />
          </Box>
        </Box>
      </Box>
    </Grommet>
  );
};

export default EmailVerification;
