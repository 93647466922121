export const content = {
  error: "error",
  success: "success",
  login_title: "Sign In",
  login_btn: "Sign In",
  login_update_title: "Login Update",
  login_update_btn: "Login Update",
  company_subtext: "to Hewlett Packard Enterprise",
  required_field: "This is a required field.",
  userID_email_label: "User ID / Email Address",
  userID_email_ph: "your.email@company.com OR user123",
  password_label: "Password",
  password_ph: "Enter your password",
  remember_me_label: "Remember me",
  forgot_pwd_link_text: "Forgot password?",
  forgot_pwd_title: "Forgot Password",
  forgot_pwd_text:
    "A link to reset your password will be sent to the following email address:",
  email_label: "Email Address",
  email_ph: "your.email@company.com",
  password_reset_btn: "Reset via email",
  successful_logout_title: "Logged out.",
  successful_logout_text:
    "You have logged out successfully. If you want to re-login again, click on the back to login button.",
  return_to_login_btn: "Return to Login",
  verify_email_title: "Verify Email Address",
  verify_email_text: "Your User ID will be your email address.",
  userID_label: "User ID",
  userID_ph: "SallyJohnson123",
  update_email_btn: "Update Email Address",
  no_update_email_btn: "No",
  verify_email_btn: "Yes",
  email_correct_question: "Is this email correct?",
  login_delayed_title: "Authenticating is still in progress",
  login_delayed_text:
    "We are still processing your request. Please try again after 10 minutes",
  change_pwd_redirect_title: "Password Reset ",
  successful_change_password_title: "Change Password Successful",
  successful_change_password_text:
    "Password has been successfully changed. Return to login to your application and make sure to login with new password from next time.",
  successful_email_verify_title: "Email Address Updated ",
  successful_email_verify_text:
    "Sign in to your application using your email address.",
  successful_email_update_title: "Email is Updated Successfully.",
  successful_email_update_text:
    "Thank you for updating your email. Your username is now updated and changed to your email address. Return to login to your application. Make sure to login with updated email going forward.",
  sent_email_title: "Email successfully sent for resetting the password.",
  sent_email_text:
    "An email with details for resetting the password has been sent to your registered email address. After resetting the password, go back to the application and login with your new password.",
  back_to_login_btn: "Sign In",
  valid_email_inline_error: "Enter a valid email address.",
  update_email_title: "Update Email Address",
  new_email_label: "New Email Address",
  confirm_new_email_label: "Confirm New Email Address",
  cancel_btn: "Cancel",
  save_btn: "Save",
  page_not_found_title: "Action not allowed",
  expire_pwd_title: "Password Expired",
  curr_pwd_label: "Current Password",
  curr_pwd_ph: "Enter your current password",
  new_pwd_label: "New Password",
  new_pwd_ph: "Enter your new password",
  confirm_pwd_label: "Confirm New Password",
  confirm_pwd_ph: "Confirm your new password",
  change_pwd_btn: "Change Password",
  footer_copyright: "Hewlett Packard Enterprise Development LP",
  expire_password_nomatch: "New and Confirm Password fields do not match",
  same_password_error: "New and old Password should be different",
  invalid_credentials_error: "Invalid credentials",
  email_update_required_error: "Email update required.",
  password_expired: "PASSWORD_EXPIRED",
  exception: "exception",
  oktaException: "oktaException",
  changeEmailResponse: "changeEmailResponse",
  email_mismatch_error:
    "New Email Address and Confirm New Email Address fields must match.",
  email_already_inuse_error: "Email already in use",
  same_email_error:
    "The new email address cannot be the same as the current email address.",
  password_requirements: "Password Requirement:",
  okta_user_locked: "User account is locked out.",
  page_not_found_text: "Please sign in again.",
  system_error: "System encountered an error. Please try again.",
  email_verification_error:
    "We are unable to process this change. Please <a>contact HPE support</a> .",
  need_help_signin: "Need help signing in?",
  forgotPassword: "Forgot Password?",
  unlockAccount: "Unlock Account?",
  help: "Help",
  signUp: "Sign up",
  contactAdmin: "Please contact admin",
  user_not_registered:
    "Username is not registered with us. Please check your username",
  inActive_user: "Invalid Credential. Please contact support for more details.",
  user_id_length_error:
    "User ID / Email address should be at least 5 characters.",
  jit_create_delay_message: "One moment while we authenticate your account.",
  password_expired_message: "User password expired. Please contact support.",
  ruleNumber_377: "377",
  ie_browser_message: "IE not supported, please use different browser.",
  okta_password_expired: "PASSWORD_EXPIRED",
  update_email_error_message:
    "This email address is already associated with another account.",
  next: "Next",
  dont_have_an_account: "Don't have an account?",
  user_name_validation: "At least 5 characters",
  reset_pwd_btn: "Reset Password",
  email_id: "Email",
  account_locked_message:
    'Your account is locked for approximately one hour because of too many unsuccessful sign-in attempts. Please wait approximately one hour for your account to unlock before attempting to sign in again. When your account is unlocked, you may click "Forgot password" or "Forgot user ID" if you cannot remember your sign-in information.',
  password_already_used_message:
    "Password already used. Please use new password.",
  change_password_failed_message: "Changing Password failed.",
  emailmask_your_email: "your.email",
  emailmask_company: "company",
  required_fp: "required",
  password_valid_1: "Enter a valid email address",
  back_to_sign_in: "Back to sign in",
  gt_evaluation_failed: "Gt evaluation failed. Please contact HPE support",
  gt_evaluation_system_error:
    "System error happened while evaluating your access policy for accessing requested application. Please try after some time. If problem persists, please contact HPE support.",
  validation_extension: "Extension field should contain only numbers",
  validation_countryCode:
    " Country Code field should only contain numbers and '+' ",

  block_page: "Block Page",
  contact_hpe_support_label: "contact HPE support",
  block_page_success_message_header:
    "Your access to the application is evaluated.",
  block_page_success_message_text:
    "Please re-login again to continue with the application access.",
  block_page_blocked_message_header:
    "You are not authorized to access the Application.",
  block_page_blocked_message_text:
    "Please <a>contact HPE support</a> team for further assistance.",
  block_page_error_message_header:
    "System error happened while evaluating your access policy for accessing requested application.",
  block_page_error_message_text:
    "Please try after some time. If problem persists, please <a>contact HPE support</a>.",
  gts_blocked_error_message:
    "Issue occurred while evaluating your access to requested application. Please <a>contact HPE support</a> for further assistance.",
  gts_system_error_message:
    "System error happened while evaluating your access policy for accessing requested application. Please try after some time. If problem persists, please <a>contact HPE support</a>.",
  forget_user_id: "Forgot User ID?",
  email_update_with_user_id: "Your User ID is your email address.",
  unlock_account_tip_message_1:
    "If you try to sign in with the wrong password multiple times, ",
  unlock_account_tip_message_2: "your account will be locked.",
  get_help_label: "Get Help",
  custom_error_page_title: "Your login session has expired due to inactivity.",
  custom_error_page_subtitle: "Please <a>Click Here</a> to login to the application.",
  click_here_text: "Click Here",
  activation_redirect_message: "Your account has been activated and being redirected to the registered application.",
  ie_browser_info: "You are currently using Edge in IE Mode which only supports limited authentication functionality. ",
  ie_browser_info_2: "To access other IAM Self-Service features including Help Pages you must login without IE Mode enabled or use another supported browser.",
};

export const complete_site_down = {
  error_title: "We are experiencing technical difficulties or undergoing a maintenance release.",
  error_subtitle: "We apologize for the inconvenience.",
  error_message: "In the meanwhile, if you require urgent technical assistance, <a>contact HPE support</a>. If this is not urgent, please try again at a later time.",
};
export const signUp = {
  primary_email: "Primary email",
  first_name: "First Name",
  last_name: "Last Name",
  street_address: "Street Address",
  street_address2: "Street Address 2",
  city: "City",
  postal_code: "Postal Code",
  country: "Country or Region",
  primary_phone: "Primary phone",
  mobile_phone: "Mobile phone",
  display_name: "Display name",
  country_code: "Country code",
  state_name: "State",
  language: "Language",
  language_coming_soon: "Language (Coming soon)",
  select_language: "Select Language",
  select_country: "Select Country or Region",
  select_timezone: "Select Time Zone",
  personal_info: "Personal Information",
  create_an_hpe_account: "Create an HPE Account",
  business_information: "BUSINESS INFORMATION",
  register: "Register",
  state_or_province: "State / Province",
  registration_subheader: "Access all of HPE with these credentials.",
  organization_information: "Organization Information",
  organization_name: "Organization Name",
  time_zone: "Time Zone",
  contact_preference: "Contact Preference",
  personalised_communications:
    "May HPE provide you with personalized communications about HPE and select HPE partner products, services, offers and events?",
  phone: "Phone",
  yes: "Yes",
  no: "No",
  select: "Select",
  create_account: "Create Account",
  signup_complete_verification_email: "Verification email sent",
  signup_complete_check_email: "To finish signing in, check your email.",
  back_to_sign_in: "Back to sign in",
  password_valid_1: "Enter a valid email address",
  required_field_1: "* Required field",
  restricted_country: "This country code is not allowed.",
  signup_complete_notification:
    "Verification email sent. To finish signing in, check your email.",
  select_one_placeholder: "Select one...",
};

export const HPE_privacy_notice = {
  privacy_policy_accept: "I accept ",
  hpe_terms_of_use: "HPE Terms of Use",
  privacy_policy_china:
    " and agree to the processing of my personal data and its transfer overseas as described in the ",
  privacy_policy_other_countries:
    " and agree to the processing of my personal data as described in the ",
  hpe_privacy_notice: "HPE Privacy Notice",
  china_policy_terms_1: "I agree that my personal information will be transferred out of China according to the: ",
  china_policy_terms_2: "Cross-border Personal Information Transfer Consent Letter.",
  china_policy_terms_3: "",
};

export const password_validation = {
  password_validation_requirement:
    "Your new password must meet the following requirements:",
  password_upper_case: "Contains at least one upper case letter",
  password_lower_case: "Contains at least one lower case letter",
  password_one_number: "Contains at least one number (0-9)",
  password_one_symbol: "Contains at least one symbol (eg., ! @ # $ % ^ &)",
  password_minimum_length: "Password length should be minimum 8 characters",
  password_not_username: "Does not contain part of email",
  password_not_firstname: "Does not contain first name",
  password_not_lastname: "Does not contain last name",
  password_not_common_pwd: "Does not contain common passwords",
  password_not_match_24pwd: "Does not match any of your last 24 passwords",
  password_last_change_one_hr:
    "At least one hour must have elapsed since you last changed your password",
  contact_hpe_support_label: "contact HPE support",
  block_page_success_message_header:
    "Your access to the application is being evaluated.",
  block_page_success_message_text:
    "Please login again to continue",
  block_page_blocked_message_header:
    "You are not authorized to access the Application.",
  block_page_blocked_message_text:
    "Please <a>contact HPE support</a> team for further assistance.",
  block_page_error_message_header:
    "System error happened while evaluating your access policy for accessing requested application.",
  block_page_error_message_text:
    "Please try after some time. If problem persists, please <a>contact HPE support</a>.",
  gts_blocked_error_message:
    "Issue occurred while evaluating your access to requested application. Please <a>contact HPE support</a> for further assistance.",
  gts_system_error_message:
    "System error happened while evaluating your access policy for accessing requested application. Please try after some time. If problem persists, please <a>contact HPE support</a>.",
};

export const passwordRequirements = {
  password_requirement_1: "At least 8 characters",
  password_requirement_2: "A lowercase letter",
  password_requirement_3: "An uppercase letter",
  password_requirement_4: "A number",
  password_requirement_5: "A symbol",
  password_requirement_6: "No parts of your username",
  password_requirement_7: "Does not include your first name",
  password_requirement_8: "Does not include your last name",
  password_requirement_9:
    "Your password cannot be any of your last 24 passwords",
};

export const signupValidation = {
  fn_validation:
    "First name should not contain any of these '/', '?', '^', '@', '&', '%', '§', '$', '£', 'æ', ''', '¿', 'œ', '�', '<', '>', '#', '|', '_', '!', '(', ')', '*', '+' characters or digits.",
  validation_51charcters: "Please enter something shorter than 50 characters",
  ln_validation:
    "Last name should not contain any of these '/', '?', '^', '@', '&', '%', '§', '$', '£', 'æ', ''', '¿', 'œ', '�', '<', '>', '#', '|', '_', '!', '(', ')', '*', '+' characters or digits.",
  general_validation:
    "This field should not contain any of these '<', '>' characters.",
  blank_validation: "This field cannot be left blank.",
  email_domain_validation: "This email domain is not allowed",
  validation_first_name_23_charcters:
    "First name cannot be over 23 characters.",
  validation_last_name_23_charcters: "Last Name cannot be over 23 characters.",
  registration_phone_number_label: "Phone Number",
  validation_phoneNumber:
    "Phone Number field should only contain numbers, space, '+' and '-'",
  errorcode_email_already_exists: "A user with this Email already exists",
  errorcode_registration_incomplete:
    "Registration cannot be completed at this time",
  password_requirement_failed:
    "Password is already used or password does not match the requirement. Please enter a valid password.",
  errorcode_not_found: "Not found: Resource not found: me (Session)",
};

export const registration_Password_Rules = {
  registration_Password_Rule_one_uppercase: "One uppercase letter",
  registration_Password_Rules_one_lowercase: "One lowercase letter",
  registration_Password_Rules_one_special: "One special character",
  registration_Password_Rules_one_number: "At least one number",
  registration_Password_Rules_14_char: "At least 14 characters",
  registration_Password_Rules_8_char: "At least 8 characters",
};

export const constants = {
  password_expired: "PASSWORD_EXPIRED",
  session_token_cookie_key: "HPPSESSION",
  exception: "exception",
  oktaException: "oktaException",
  changeEmailResponse: "changeEmailResponse",
  ruleNumber_377: "377",
  different_email_userid_error: "Email and userId is different",
  email_update_required_error: "Email update required.",
  okta_user_locked_error_message: "User account is locked out.",
  okta_password_expired: "PASSWORD_EXPIRED",
  error: "error",
  success: "success",
  password_already_used:
    "Password is already used or password does not match the requirement! Please enter a valid password",
  app_title_dev: "Hewlett Packard Enterprise (HPE) - Auth Dev - Sign In",
  app_title_itg: "Hewlett Packard Enterprise (HPE) - Auth ITG - Sign In",
  ie_browser_info: "You are currently using Edge in IE Mode which only supports limited authentication functionality. ",
  ie_browser_info_2: "To access other IAM Self-Service features including Help Pages you must login without IE Mode enabled or use another supported browser.",
  SUCCESS: "SUCCESS",
  invalid_credentials: "INVALID_CREDENTIALS",
  AUTHENTICATION_FAILED: "AUTHENTICATION FAILED",
  UPDATE_CREDENTIALS_FAILED: "UPDATE OF CREDENTIALS FAILED",
  RECOVERY_CHALLENGE: "RECOVERY_CHALLENGE",
};

export const blockPageErrorMessages = {
  block_page_success_message: {
    header: content.block_page_success_message_header,
    text: content.block_page_success_message_text,
  },
  block_page_blocked_message: {
    header: content.block_page_blocked_message_header,
    text: content.block_page_blocked_message_text,
  },
  block_page_error_message: {
    header: content.block_page_error_message_header,
    text: content.block_page_error_message_text,
  },
};
