import React, { useContext, useEffect } from "react";
import { Grommet, Box, Text, Spinner } from "grommet";
import { hpe } from "grommet-theme-hpe";
import { useLocation } from "react-router";
import { AuthContext } from "../../AuthContext";
import Cookies from "js-cookie";
import { oktaAppHome, blockPageUrl } from "../../Helpers/urls";
import {
  activateRedirectCookieUpdate,
  forgotPasswordActivateRedirectCookieUpdate,
} from "../../Helpers/common";

const SignupComplete = () => {
  const { state } = useContext(AuthContext);
  const { isAppMounted, content } = state;
  const { activation_redirect_message } = content;
  const location = useLocation();
  const search = location.search;
  let isOidcApp = new URLSearchParams(search).get("oidc_app");
  let isDefaultApp = new URLSearchParams(search).get("default_app");


  useEffect(() => {
    if (isAppMounted) {
      const deepLink = Cookies.get("activationRedirect");
      const getActivationRedirect = localStorage.getItem("activationRedirect");
      let redirectUri;

      if (isDefaultApp) {
        if (deepLink) {
          redirectUri = deepLink;
        } else if (getActivationRedirect) {
          redirectUri = decodeURIComponent(getActivationRedirect);
        } else {
          redirectUri = oktaAppHome;
        }
        activateRedirectCookieUpdate("");
        localStorage.removeItem("activationRedirect");
        window.location.href = decodeURIComponent(redirectUri);
        return;
      }

      if (!deepLink) {
        window.location.href = isOidcApp
          ? `${blockPageUrl}/?blockURL=IAM`
          : oktaAppHome;

        return;
      }
      activateRedirectCookieUpdate("");
      window.location.href = decodeURIComponent(deepLink);
    }
  }, [isAppMounted]);

  return (
    <Grommet theme={hpe}>
      <Box
        flex
        gap="medium"
        width="medium"
        className="form-container"
        alignContent="center"
      >
        <Box margin="20px 0px" align="center" style={{ textAlign: "center" }}>
          {isDefaultApp ? (
            <Box align="center" justify="center" height={{ min: "small" }}>
              <Spinner size="medium" />
            </Box>
          ) : (
            <Text size="large" weight="bold">
              {activation_redirect_message}
            </Text>
          )}
        </Box>
      </Box>
    </Grommet>
  );
};

export default SignupComplete;
