import React, { useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Grommet,
  Box,
  Button,
  Form,
  FormField,
  Text,
  TextInput,
  Spinner,
  Anchor,
  Tip,
} from "grommet";
import { Hide, View, CircleInformation } from "grommet-icons";
import { fetchDataAxios } from "../../Data/fetchData";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import { ACTIONS } from "../../AuthReducer";
import { oktaLoginRequestPayload } from "../../Helpers/requestsPayload";
import { constants } from "../../Helpers/content";
import AlertBanner from "../AlertBanner";
import { okta_login_endpoint } from "../../Helpers/endpoints";
import {
  change_pwd_path,
  oktaAppHome,
  redirectUrlString,
  sessionCookieRedirectUrl,
  unlockUrl,
  okta_user_lang_cookie_domain,
  enviroment,
} from "../../Helpers/urls";
import { hpe } from "grommet-theme-hpe";
import {
  setLoginCookie,
  getLanguageAndCountryCode,
  isLocalDev,
  oktaCookieUpdate,
  dynamicHelpPage,
} from "../../Helpers/common";
import Title from "../header/Title";

const Login = () => {
  const location = useLocation();
  const { username, fromUri, relayState, applicationContextFromUri, redirectUri } =
    location.state;
  const [formValues, setFormValues] = useState({
    email: username ? username : localStorage.getItem("email").trim(),
    password: localStorage.getItem("password"),
  });
  const [setHelpVisible] = useState(false);
  const [pwdReveal, setPwdReveal] = useState(false);
  const [pwdValue, setPwdValue] = useState("");
  const [email, setEmail] = useState(username);
  const [showSpinner, setShowSpinner] = useState(false);
  let history = useHistory();
  const { state, dispatch } = useContext(AuthContext);
  const [credentialError, setCredentialError] = useState(false);
  const [message, setMessage] = useState("");
  const { isIE } = state;

  const {
    system_error,
    signUp,
    invalid_credentials_error,
    login_title,
    required_field,
    userID_email_label,
    userID_email_ph,
    password_label,
    password_ph,
    login_btn,
    forgotPassword,
    unlockAccount,
    jit_create_delay_message,
    dont_have_an_account,
    forget_user_id,
    email_update_with_user_id,
    unlock_account_tip_message_1,
    unlock_account_tip_message_2,
    get_help_label,
  } = state.content;

  const {
    password_expired,
    SUCCESS,
    AUTHENTICATION_FAILED
  } = constants;

  const { selectedLanguage, supportedLanguagesListDetails } = state;

  useEffect(() => {
    setFormValues({
      email: username,
    });
    setEmail(username);
    //eslint-disable-next-line
  }, []);
  const onSubmit = async () => {
    loginUser();
  };
  const onChange = (values) => {
    setFormValues(values);
  };

  const getSessionCookieRedirectUrl = (loginOktaResponse, fromURI) =>
    `${sessionCookieRedirectUrl}${loginOktaResponse?.sessionToken}${redirectUrlString}${fromURI}`;

  const getApplicationContext = (applicationContextFromUri) => {
    const decodedUri = decodeURIComponent(applicationContextFromUri);
    const decodedApplicationContext = decodeURIComponent(decodedUri);
    return applicationContextFromUri
      ? decodedApplicationContext?.includes("/v1/authorize")
        ? redirectUri || applicationContextFromUri
        : applicationContextFromUri
      : "";
  };

  const loginUser = async () => {
    setShowSpinner(true);
    try {
      const loginResponse = await fetchDataAxios(
        okta_login_endpoint,
        oktaLoginRequestPayload({
          ...formValues,
          ...{
            applicationContext: getApplicationContext(
              applicationContextFromUri
            ),
          },
        })
      );
      const loginResponseStatus = loginResponse?.status?.toUpperCase();
      if (loginResponseStatus === SUCCESS) {
        updateCookie(loginResponse);
        const fromURI = fromUri === null ? oktaAppHome : fromUri;
        window.location = getSessionCookieRedirectUrl(loginResponse, fromURI);
      } else if (loginResponseStatus === password_expired) {
        setShowSpinner(false);
        dispatch({ type: ACTIONS.changePassword });
        history.push({
          pathname: change_pwd_path,
          state: { userId: formValues.email, stateToken: loginResponse.stateToken },
        });
      } else if (loginResponse?.errorSummary?.toUpperCase() === AUTHENTICATION_FAILED) {
        setShowSpinner(false);
        setCredentialError(true);
        setMessage(invalid_credentials_error);
      } else {
        setShowSpinner(false);
        setCredentialError(true);
        setMessage(system_error);
      }
    } catch (err) {
      setShowSpinner(false);
      setCredentialError(true);
      if (err && err?.response?.status === 401)
        setMessage(invalid_credentials_error);
      else setMessage(system_error);
    }
  };

  const updateCookie = (data) => {
    let langWithLocale = data?._embedded?.user?.profile?.locale;
      if (langWithLocale) {
        langWithLocale = langWithLocale.toLowerCase();
        if (langWithLocale === "en" || langWithLocale === "en_us" || langWithLocale === "en-us") {
          Cookies.remove('hpeIamLang', { path: '/', domain: isLocalDev() ? 'localhost' : '.hpe.com' });
          oktaCookieUpdate('', okta_user_lang_cookie_domain);
        } else {
          const { _lang, _cc } = getLanguageAndCountryCode(langWithLocale);
          setLoginCookie(_lang, _cc, okta_user_lang_cookie_domain);
        }
      }
  }

  const registrationHandler = () => {
    if (!relayState) {
      history.push('/registration');
      return;
    }
    history.push(`/registration${relayState}`);
  };


  const forgotPasswordHandler = () => history.push('/forgot-password');

  return (
    <Grommet theme={hpe}>
      {showSpinner ? (
        <Box align="center" justify="center" height={{ min: "small" }}>
          <Spinner size="medium" />
          <Text size="xxlarge" weight="bold">
            {jit_create_delay_message}
          </Text>
        </Box>
      ) : (
        <Box
          flex
          gap="small"
          width="medium"
          className="form-container"
          alignContent="center"
          margin={{ horizantal: "small" }}
        >
          <Title info={{ title: `HPE ${login_title}` }} />
          <Box pad={{ horizontal: "xxsmall" }}>
            <Form
              validate="blur"
              value={formValues}
              onChange={(value) => onChange(value)}
              messages={{
                required: required_field,
              }}
              onSubmit={() => onSubmit()}
            >
              {/* General Alert Banners */}
              {credentialError && <AlertBanner message={message} />}

              <FormField
                required
                label={userID_email_label}
                name="email"
                htmlFor="email-sign-in"
                disabled={username ? true : false}
              >
                <TextInput
                  id="email-sign-in"
                  name="email"
                  placeholder={userID_email_ph}
                  className="custom-field"
                  value={email.trim()}
                  disabled={username ? true : false}
                />
              </FormField>

              <FormField
                required
                label={password_label}
                htmlFor="password-sign-in"
                name="password"
              >
                <Box direction="row">
                  <TextInput
                    id="password-sign-in"
                    name="password"
                    plain
                    type={pwdReveal ? "text" : "password"}
                    value={pwdValue}
                    placeholder={password_ph}
                    onChange={(event) => setPwdValue(event.target.value)}
                    className="custom-field"
                  />
                  <Button
                    icon={
                      pwdReveal ? (
                        <View size="medium" />
                      ) : (
                        <Hide size="medium" />
                      )
                    }
                    onClick={() => setPwdReveal(!pwdReveal)}
                  />
                </Box>
              </FormField>
              <Box
                align="start"
                margin={{ top: "small", bottom: "small" }}
                fill="horizontal"
                direction="row"
                gap="medium"
                justify="start"
              >
                <Button
                  label={login_btn}
                  // fill="horizantal"
                  // icon={<FormNext />}
                  size="medium"
                  reverse
                  primary
                  type="submit"
                  className="submit-btn"
                  style={{ padding: "7px 50px" }}
                />
              </Box>
            </Form>
          </Box>
          {!isIE && (
            <Box gap="xxsmall">
              <Box direction="row" align="center">
                <Button onClick={forgotPasswordHandler} size="xxxsmall">
                  <u className="bold-font-500">{forgotPassword}</u>
                </Button>
              </Box>
              <Box direction="row" align="center">
                <Text weight={"500"}>{forget_user_id}</Text>
                <Tip
                  content={
                    <Box pad="small" gap="xxsmall">
                      <Text size="14px">{email_update_with_user_id}</Text>
                    </Box>
                  }
                  dropProps={{ align: { left: "right" } }}
                >
                  <Button
                    a11yTitle="close"
                    size="small"
                    icon={<CircleInformation color="rgba(23,235,160,1)" />}
                  />
                </Tip>
              </Box>
              <Box direction="row" align="center">
                <Anchor
                  label={unlockAccount}
                  href={unlockUrl}
                  onClick={() => setHelpVisible(true)}
                />
                <Tip
                  content={
                    <Box pad="small" gap="xxsmall">
                      <Text size="14px">{unlock_account_tip_message_1}</Text>
                      <Text size="14px">{unlock_account_tip_message_2}</Text>
                    </Box>
                  }
                  dropProps={{ align: { left: "right" } }}
                >
                  <Button
                    a11yTitle="close"
                    size="small"
                    icon={<CircleInformation color="rgba(23,235,160,1)" />}
                  />
                </Tip>
              </Box>
              <Box direction="row" gap="small">
                <Text>{dont_have_an_account}</Text>
                <div className="link-container">
                  <Button onClick={registrationHandler} size="xxxsmall">
                    <u className="bold-font-500">{signUp}</u>
                  </Button>
                </div>
              </Box>

              <Box>
                <Anchor
                  label={get_help_label}
                  target="_blank"
                  rel="noopener"
                  href={dynamicHelpPage(
                    enviroment,
                    supportedLanguagesListDetails,
                    selectedLanguage
                  )}
                  onClick={() => setHelpVisible(true)}
                />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Grommet>
  );
};

export default Login;