import React from 'react'
import { Box, Text } from 'grommet'
import { CircleAlert } from 'grommet-icons';
const AlertBanner = ({message}) => {
    return (
        <Box
            animation="fadeIn"
            align="center"
            background="validation-critical"
            direction="row"
            gap="xsmall"
            margin={{ top: 'xxsmall', bottom: 'xxsmall' }}
            pad="small"
            round="4px"
        >
            <CircleAlert size="small" />
            <Text size="xsmall">{message}</Text>
        </Box>
    )
}

export default AlertBanner
