import React, { useEffect, useContext } from "react";
import { Box, Grommet, Spinner } from "grommet";
import { hpe } from "grommet-theme-hpe";
import { useLocation } from "react-router-dom";
import { deleteFetch } from "../../Data/fetchData";
import { session_endpoint } from "../../Helpers/endpoints";
import { logoutUrl } from "../../Helpers/urls";
import { AuthContext } from "../../AuthContext";

const LogoutRedirect = () => {
  const { state } = useContext(AuthContext);
  const { isAppMounted } = state;

  const location = useLocation();
  const search = location.search;
  let appLandingPage = new URLSearchParams(search).get("applandingpage");
  appLandingPage = appLandingPage === null ? logoutUrl : appLandingPage;

  useEffect(() => {
    if (isAppMounted) deleteSession();
  }, [isAppMounted]);

  const deleteSession = async () => {
    try {
      await deleteFetch(session_endpoint);
      window.location = appLandingPage;
    } catch (err) {
      return err;
    }
  };

  return (
    <Grommet theme={hpe}>
      <Box align="center" justify="center" height={{ min: "small" }}>
        <Spinner size="medium" />
      </Box>
    </Grommet>
  );
};

export default LogoutRedirect;
