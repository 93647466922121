import React, { useState, useEffect, useContext } from "react";
import { Grommet, Box, Spinner } from "grommet";
import { hpe } from "grommet-theme-hpe";
import { useLocation } from "react-router";
import Cookies from "js-cookie";
import { fetchData } from "../../Data/fetchData";
import { gts_validate, session_endpoint, okta_current_user_info_endpoint } from "../../Helpers/endpoints";
import { getValidateSIDFetch, getData, deleteFetch } from "../../Data/fetchData";
import { oktaAppHome, hpeDomainLoginUrl, centralFormUrl } from "../../Helpers/urls";
import { AuthContext } from "../../AuthContext";
import { isAbsoluteURL, isRedirectURL, decodeURL, doubleEncoderUrl, extractClientId } from "../../Helpers/common";
import { constants } from "../../Helpers/constants";
import ConsentPage from "./ConsentPage";
import GtErrorPage from "./GtErrorPage";

const BlockPage = () => {
  const [showSpinner, setShowSpinner] = useState(true);
  const [showChinaConsent, setShowChinaConsent] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [consentPageError, setConsentPageError] = useState({});
  const { state } = useContext(AuthContext);
  const { isAppMounted } = state;

  const [statusCode, setStatusCode] = useState(400);
  const location = useLocation();
  const search = location.search;
  let blockURL = new URLSearchParams(search).get("blockURL");
  let fromURI = localStorage.getItem('fromURI');
  const checkRediectURL = isRedirectURL(fromURI);
  if (fromURI) fromURI = decodeURL(fromURI);
  const activationRedirectCookie = Cookies.get("activationRedirect");
  const showCancelOption =
    !(fromURI && activationRedirectCookie) &&
    (checkRediectURL || isAbsoluteURL(activationRedirectCookie));

  useEffect(() => {
    if (blockURL && blockURL.toLowerCase() === "gt_blocked_user") {
      setShowSpinner(false);
      setStatusCode(403);
      return;
    }
    if (isAppMounted) {
      onBlockpageInitialization(blockURL, okta_current_user_info_endpoint, checkRediectURL, fromURI);
    }
  }, [isAppMounted]);

  const onBlockpageInitialization = async (blockURL, userInfoURL, checkRediectURL, fromURI) => {
    try {
      const userInfo = await getUserInfo(userInfoURL);
      if (userInfo.showChinaConsent) {
        setShowSpinner(false);
        return;
      };
      await validateGtFlow(blockURL, checkRediectURL, fromURI);
    } catch (e) {
      setStatusCode(400);
      setShowSpinner(false);
      console.log(e);
    }
  };

  const validateGtFlow = async (blockURL, checkRediectURL, fromURI) => {
    if (!blockURL) blockURL = "";
    setShowSpinner(true);
    try {
      const sessionResponse = await getValidateSIDFetch(session_endpoint, "");
      const sid =
        sessionResponse && sessionResponse.id ? sessionResponse.id : "";
      const res = await fetchData(`${gts_validate}`, {
        sid,
        applicationContext: blockURL,
      });
      gtScreening(res, checkRediectURL, fromURI);
    } catch (err) {
      setShowSpinner(false);
      return err;
    }
  };

  const gtScreening = (data, checkRediectURL, fromURI) => {
    const errorCode = data?.gtStatusCode ? data.gtStatusCode : 400;
    const errorMessage = data?.message;
    if (errorCode === 200 && errorMessage === constants.GT_SCREENING_SUCCESS) {
      if (modalLoginRedirect(fromURI)) return;
      if (checkRediectURL && fromURI && !activationRedirectCookie) {
        if (!decodeURIComponent(fromURI).includes(constants.OKTA_KEY)) {
          redirectBackToApplication(hpeDomainLoginUrl, fromURI);
          return;
        }
      }
    }
    setStatusCode(errorCode);
    setShowSpinner(false);
  };

  const getUserInfo = async (url) => {
    try {
      const getUserResponse = await getData(url);
      if (!getUserResponse || !getUserResponse.profile)
        return { showChinaConsent: false };
      const cc = getUserResponse.profile.hpeCountryCode;
      const tc = getUserResponse.profile.hpe_terms_conditions_china_privacy;
      if (cc && cc.toLowerCase() === "cn") {
        if (!tc || tc === "false") {
          setShowSpinner(false);
          setShowChinaConsent(true);
          setUserInfo(getUserResponse.profile);
          return { showChinaConsent: true };
        }
      }
      return { showChinaConsent: false };
    } catch (err) {
      return err;
    }
  };

  const updateUserInfo = async (url, payload, blockURL, fromURI, hpeDomainLoginUrl, checkRediectURL) => {
    try {
      const updateUserResponse = await fetchData(url, {
        profile: {
          email: payload.login,
          hpe_terms_conditions_china_privacy: true,
        },
      });
      if (updateUserResponse && updateUserResponse.errorCode) {
        setConsentPageError({isError: true, error: updateUserResponse});
        return;
      }
      if (fromURI && decodeURIComponent(fromURI).includes(constants.OKTA_KEY)) {
        setStatusCode(200);
        setShowSpinner(false);
        setShowChinaConsent(false);
        return;
      }
      if (modalLoginRedirect(fromURI)) return;
      if (checkRediectURL && fromURI && !activationRedirectCookie) {
          redirectBackToApplication(hpeDomainLoginUrl, fromURI);
          return;
      }
      await validateGtFlow(blockURL, checkRediectURL, fromURI);
      setShowChinaConsent(false);
    } catch (err) {
      setConsentPageError({isError: true, error: err});
      return err;
    }
  };

  const redirectBackToApplication = (redirectDomain, redirectUri) => {
    const fromUri =
      !redirectUri || redirectUri === oktaAppHome || redirectUri === constants.IAM
        ? oktaAppHome
        : isAbsoluteURL(redirectUri)
        ? redirectUri
        : redirectDomain + redirectUri;
    window.location = fromUri;
  };

  const modalLoginRedirect = (fromURI) => {
    if (activationRedirectCookie) {
      if (fromURI) {
        try {
          if (blockURL && isAbsoluteURL(activationRedirectCookie)) {
            const url = new URL(decodeURIComponent(activationRedirectCookie));
            if (decodeURIComponent(blockURL).includes(url?.hostname)) {
              window.location = decodeURIComponent(activationRedirectCookie);
              return true;
            }
          } 
          if (fromURI.includes(constants.CLIENT_ID) && blockURL.includes(constants.CLIENT_ID)) {
            if (extractClientId(fromURI) === extractClientId(blockURL)) {
              redirectBackToApplication(hpeDomainLoginUrl, fromURI);
              return true;
            }
          } 
          setStatusCode(200);
          setShowSpinner(false);
          setShowChinaConsent(false);
          return true;
        } catch (err) {
          return false;
        }
      } else {
        if (isAbsoluteURL(activationRedirectCookie)) {
          window.location = decodeURIComponent(activationRedirectCookie);
        } else {
          setStatusCode(200);
          setShowSpinner(false);
          setShowChinaConsent(false);
        }
        return true;
      }
    }
    return false;
  };

  const saveConsent = (value, touched) =>
    updateUserInfo(okta_current_user_info_endpoint, userInfo, blockURL, fromURI, hpeDomainLoginUrl, checkRediectURL);

  const cancelModalLogin = (activationRedirectCookie) => {
    if (isAbsoluteURL(activationRedirectCookie)) {
      window.location = decodeURIComponent(activationRedirectCookie);
      return true;
    }
    return false;
  }
  
  const cancelConsent = async () => {
    try {
      await deleteFetch(session_endpoint);
      if (cancelModalLogin(activationRedirectCookie)) return;
      window.location = `${centralFormUrl}?fromURI=${doubleEncoderUrl(fromURI) || oktaAppHome}`;
    } catch (err) {
      setConsentPageError({isError: true, error: err});
      return err;
    }
  }

  if (showSpinner) {
    return (
      <Grommet theme={hpe}>
        <Box align="center" justify="center" height={{ min: "small" }}>
          <Spinner size="medium" />
        </Box>
      </Grommet>
    );
  }

  return (
    <Grommet theme={hpe}>
      {showChinaConsent ? (
        <ConsentPage
          saveConsent={saveConsent}
          cancelConsent={cancelConsent}
          consentPageError={consentPageError}
          showCancelButton={showCancelOption}
        />
      ) : (
        <GtErrorPage statusCode={statusCode} />
      )}
    </Grommet>
  );
};

export default BlockPage;
