import React, { useContext } from 'react';
import {
  Grommet,
  Box,
  Button,
  Header,
  ResponsiveContext,
  Text
} from 'grommet';
import { hpe } from 'grommet-theme-hpe';
import { FormNext } from 'grommet-icons';
import { useHistory, Link } from 'react-router';
import { content } from '../../Helpers/content';
import { AuthContext } from '../../AuthContext';
import Title from "../header/Title";

const EmailVerificationSuccess = () => {
  const size = useContext(ResponsiveContext);
  const { state } = useContext(AuthContext)
  const history = useHistory()

  const onLoginClick = async()=>{
    if (localStorage.getItem('fromURI')) {
      history.push({
        pathname: '/',
        search: '?fromURI=' + encodeURIComponent(localStorage.getItem('fromURI'))
      });
    } else {
      history.push('/')
    }
  }

  return (
    <Grommet theme={hpe}>
      <Box
        flex
        gap="small"
        width="medium"
        className="form-container"
        alignContent='center'
      >
        <Title info={{ title: `HPE ${state.content.login_title}` }} />
        <Header
          direction="column"
          align="start"
          gap="xxsmall"
          pad={{ horizontal: 'xxsmall' }}
          className="form-header"
        >
          <Text size="xlarge" weight="bold">
          {state.content.successful_email_verify_title}
          </Text>
        </Header>
        
        <Text size="small" weight="normal">
          {state.content.verify_email_text}
        </Text>
        <Text size="large" weight="bold">
          {state.content.successful_email_verify_text}
        </Text>
        
        <Box
          align="start"
          margin={{ top: 'medium', bottom: 'small' }}
        >        
          <Button
            label={state.content.back_to_login_btn}
            style={{padding: "5px 20px"}}
            reverse
            primary
            onClick ={()=>{onLoginClick()}}
            className="submit-btn"
          />
        </Box>
      </Box>
    </Grommet>
  );
};

export default EmailVerificationSuccess