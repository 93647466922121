import React, { useContext } from 'react';
import {
  Grommet,
  Box,
  Button,
  Header,
  ResponsiveContext,
  Text
} from 'grommet';
import { hpe } from 'grommet-theme-hpe';
import { useHistory } from 'react-router';
import { AuthContext } from '../../AuthContext';
import { appendFromURI } from "../../Helpers/common";

const SuccessComponent = () => {
  const size = useContext(ResponsiveContext);
  const { state } = useContext(AuthContext)
  const history = useHistory()
  const {successful_change_password_title,successful_change_password_text, back_to_login_btn} = state.content
  
  const onLoginClick = async () => {
    history.push(appendFromURI());
  };

  return (
    <Grommet theme={hpe}>
      <Box
        flex
        gap="medium"
        width="medium"
        className="form-container"
        alignContent='center'
      >
        <Header
          direction="column"
          align="start"
          gap="xxsmall"
          pad={{ horizontal: 'xxsmall' }}
          className="form-header"
        >
          <Text size="xxlarge" weight="bold">
            {successful_change_password_title}
          </Text>
        </Header>
        
        <Text size="small" weight="normal">
          {successful_change_password_text}
        </Text>
        
        <Box align="start">
          <Button
            label={back_to_login_btn}
            size="medium"
            primary
            onClick={() => {
              onLoginClick();
            }}
            style={{ padding: "5px 30px" }}
            className="submit-btn"
          />
        </Box>
      </Box>
    </Grommet>
  );
};

export default SuccessComponent