import { content } from "../Helpers/content";

export const emailValidation = [
    {
      regexp: new RegExp('[^@ \\t\\r\\n]+@'),
      message: 'Enter a valid email address.',
      status: 'error',
    },
    {
      regexp: new RegExp('[^@ \\t\\r\\n]+@[^@ \\t\\r\\n]+\\.[^@ \\t\\r\\n]+'),
      message: 'Enter a valid email address.',
      status: 'error',
    },
    {
      regexp: new RegExp('[^@ \\t\\r\\n]+@[^@ \\t\\r\\n]+\\.[^@ \\t\\r\\n]+'),
      message: 'Enter a valid email address.',
      status: 'error',
    },
  ];
  export const passwordRulesStrong = [
    {
      regexp: new RegExp('(?=.*?[A-Z])'),
      message: 'One uppercase letter',
      status: 'error',
    },
    {
      regexp: new RegExp('(?=.*?[a-z])'),
      message: 'One lowercase letter',
      status: 'error',
    },
    {
      regexp: new RegExp('(?=.*?[#?!@$ %^&*-])'),
      message: 'One special character',
      status: 'error',
    },
    {
      regexp: new RegExp('(?=.*?[0-9])'),
      message: 'At least one number',
      status: 'error',
    },
    {
      regexp: new RegExp('.{8,}'),
      message: 'At least 8 characters',
      status: 'error',
    },
  ];
  export const usernameLength = [
    {
      regexp: new RegExp('.{5,}'),
      message: content.user_name_validation,
      status: 'error',
    }
  ]
 export const emailMask = [
    {
      regexp: /^[\w\-_.+]+$/,
      placeholder: 'your.email',
    },
    { fixed: '@' },
    {
      regexp: /^[\w]+$/,
      placeholder: 'company',
    },
    // { fixed: '.' },
    {
      regexp: /^[\w.]+$/,
      placeholder: '.com',
    },
  ];

 export const registrationEmailMask = [
   {
     regexp: /^[\w\-_.+@]+$/,
   },
   {
     regexp: /^[\w]+$/,
   },
   {
     regexp: /^[\w.]+$/,
   },
 ];

 export const registrationNameMask = [
   {
     regexp: /^(?=.*\S).+$/,
   },
 ];

 export const registrationPasswordRulesStrong = [
  {
    regexp: new RegExp('(?=.*?[A-Z])'),
    message: 'One uppercase letter',
    status: 'error',
  },
  {
    regexp: new RegExp('(?=.*?[a-z])'),
    message: 'One lowercase letter',
    status: 'error',
  },
  {
    regexp: new RegExp('(?=.*?[#?!@$ %^&*-])'),
    message: 'One special character',
    status: 'error',
  },
  {
    regexp: new RegExp('(?=.*?[0-9])'),
    message: 'At least one number',
    status: 'error',
  },
  {
    regexp: new RegExp('.{14,}'),
    message: 'At least 14 characters',
    status: 'error',
  },
];
