import React, { useContext } from "react";
import { Grommet, Box, Header, Text, Spinner, Anchor, Button } from "grommet";
import { hpe } from "grommet-theme-hpe";
import { AuthContext } from "../../AuthContext";
import { content } from "../../Helpers/content";

const OOTBSessionExpired = () => {
  const { state } = useContext(AuthContext);
  const {
    custom_error_page_title,
    custom_error_page_subtitle,
    click_here_text,
  } = state.content;

  const formatAnchorTag = (message, label) => {
    if (!message.includes("<a>")) return message;
    return (
      <div>
        {message.slice(0, message.indexOf("<a>"))}{" "}
        <Anchor
          label={label}
          target="_blank"
          onClick={() => {
            onLoginClick();
          }}
        />
        {message.slice(message.indexOf("</a>") + 4)}
      </div>
    );
  };

  const onLoginClick = async () => {
    window.history.go(-4);
  };

  return (
    <Grommet theme={hpe}>
      <Box
        flex
        gap="medium"
        width="large"
        className="form-container"
        alignContent="center"
      >
        <>
          <Header
            direction="column"
            className="form-header text-align-center"
          >
            <Text size="xlarge" weight="bold">
              {custom_error_page_title}
            </Text>
          </Header>
          <Box margin="20px 0px" align="center" className="text-align-center">
            {formatAnchorTag(custom_error_page_subtitle, click_here_text)}
          </Box>
        </>
      </Box>
    </Grommet>
  );
};

export default OOTBSessionExpired;
