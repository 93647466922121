import { useReducer } from "react";
import { AuthContext } from "./AuthContext";
import { authReducer } from "./AuthReducer";

import {
  content,
  passwordRequirements,
  HPE_privacy_notice,
  password_validation,
  signupValidation,
  registration_Password_Rules,
  signUp,
  complete_site_down
} from "./Helpers/content";
import { countryList, timezoneList } from "./Data/registration.js";

let initialState = {
  loginAuth: true,
  updateEmailAuth: false,
  verifyEmailAuth: false,
  logoutAuth: true,
  emailVerificationSuccessAuth: false,
  emailUpdateSuccessAuth: false,
  changePasswordAuth: true,
  sendEmailAuth: false,
  forgotPasswordAuth: true,
  successComponentAuth: false,
  loginDelayAuth: false,
  forgotPwdRedirectAuth: false,
  content,
  countryList,
  timezoneList,
  HPE_privacy_notice,
  signupValidation,
  registration_Password_Rules,
  password_validation,
  passwordRequirements,
  selectedLanguage: "",
  languageCode: "en",
  registrationSuccessNotification: false,
  signUp,
  complete_site_down,
  goBackUpdateComponent: "",
  supportedLanguagesList: [],
  supportedLanguagesListDetails: [],
  supportedHeaderFooterList: [],
  embargoCountryList: [],
  backToSigninFlag: null,
  signupLanguage: "",
  deepLinkApplicationsList: [],
  isAppMounted: false,
  isIE: false,
  isBrowserDefaultLang: false,
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
