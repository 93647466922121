import axios from 'axios';

export const fetchData = async (url, payload, allowCookie=true) => {
  return new Promise(async (resolve, reject) => {
    const controller = new AbortController();
    const timer = setTimeout(() => {
      controller.abort();
    }, 60000);

    try {
      const res = await fetch(url, {
        method: "POST",
        credentials: allowCookie ? 'same-origin': 'omit',
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": "en",
        },
        body: JSON.stringify(payload),
        signal: controller.signal,
      });
      clearTimeout(timer);
      resolve(res.json());
    } catch (err) {
      clearTimeout(timer);
      reject({
        ...err,
        errorCode: "system_error",
      });
    }
  });
};

export const fetchDataAxios = async (url, payload) => {
  try {
    const data = await axios({
      method: "post",
      url: url,
      data: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        // "Accept-Encoding": "gzip, deflate, br",
        // "Accept-Language:": "en-US,en;q=0.9",
        // "Access-Control-Request-Headers": "content-type",
        // "Sec-Fetch-Mode": "cors",
        // "Sec-Fetch-Site": "same-site",
      },
    });
    // const data = await axios.post(url, {data: payload, headers: {"Accept": "application/json, text/plain, */*"}})
    return data?.data;
  } catch (err) {
    throw err;
  }
};

export const getData = async (url) => {
  try {
    const res = await fetch(url);
    const data = await res.json();
    if (!data) return;
    return data;
  } catch (err) {
    throw err;
  }
};

export const getDataAxios = async (url) => {
  try {
    const data = await axios.get(url);
    return data?.data;
  } catch (err) {
    throw err;
  }
};


export const deleteFetch = async (url) => {
  try {
    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin":
          "https://auth-itg.hpe.com, https://auth-dev.hpe.com, https://auth.hpe.com",
        "Access-Control-Allow-Methods":
          "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      },
      credentials: "include",
    });
    const data = await res.json();
    return await data;
  } catch (err) {
    return err;
  }
};

export const getValidateSIDFetch = async (url, sid) => {
  try {
    const res = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    });
    const data = await res.json();
    return await data;
  } catch (err) {
    throw err;
  }
};

export const getValidateSIDFetchAxios = async (url, sid) => {
  try {
    const data = await axios.get(url);
    return data?.data;
  } catch (err) {
    throw err;
  }
}

export const validateGtFlow = async (gts_validate, applicationContext, sid) => {
  if (!applicationContext) applicationContext = "";
  try {
    const res = await fetchData(`${gts_validate}`, {
      sid,
      applicationContext,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const validateGtFlowAxios = async (gts_validate, applicationContext, sid) => {
  if (!applicationContext) applicationContext = "";
  try {
    const res = await fetchDataAxios(`${gts_validate}`, {
      sid,
      applicationContext,
    });
    return res;
  } catch (err) {
    return err;
  }
};