import React, { useContext } from "react";
import {
  Grommet,
  Box,
  Header,
  ResponsiveContext,
  Text,
  Button,
  Anchor,
} from "grommet";
import { hpe } from "grommet-theme-hpe";
import { useHistory } from "react-router";
import { AuthContext } from "../AuthContext";
import { ACTIONS } from "../AuthReducer";
import Title from "./header/Title";
import { getHelpUrl, appendFromURI, dynamicHelpPage } from "../Helpers/common";
import { enviroment } from "../Helpers/urls"

const PageNotFound = () => {
  const size = useContext(ResponsiveContext);
  const history = useHistory();
  const { state, dispatch } = useContext(AuthContext);
  const { selectedLanguage, supportedLanguagesListDetails } = state;
  const {
    page_not_found_title,
    page_not_found_text,
    back_to_login_btn,
    login_title,
    get_help_label,
  } = state.content;

  const onBacktoLogin = () => {
    dispatch({ type: ACTIONS.login });
    history.push(appendFromURI());
  };

  return (
    <Grommet theme={hpe}>
      <Box
        flex
        gap="small"
        width="medium"
        className="form-container"
        alignContent="center"
      >
        <Title info={{ title: `HPE ${login_title}` }} />
        <Header
          direction="column"
          align="start"
          gap="xxsmall"
          pad={{ horizontal: "xxsmall" }}
          className="form-header"
        >
          <Text size="xlarge" weight="bold">
            {page_not_found_title}
          </Text>
        </Header>

        <Text size="small" weight="normal">
          {page_not_found_text}
        </Text>

        <Box margin={{ top: "medium", bottom: "small" }} align="start">
          <Button
            label={back_to_login_btn}
            primary
            onClick={() => onBacktoLogin()}
            className="submit-btn"
            style={{ padding: "7px 50px" }}
          />
        </Box>
        <Box margin={{ top: "medium", bottom: "medium" }}>
          <Anchor
            label={get_help_label}
            target="_blank"
            rel="noopener"
            href={dynamicHelpPage(enviroment, supportedLanguagesListDetails, selectedLanguage)}
          />
        </Box>
      </Box>
    </Grommet>
  );
};

export default PageNotFound;
