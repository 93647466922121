
export const ACTIONS = {
    login: 'login',
    updateEmail: 'updateEmail',
    verifyEmail: 'verifyEmail',
    logout: 'logout',
    emailverificationsuccess: 'emailVerificationSuccess',
    changePassword: 'changePassword',
    emailupdateSuccess: 'emailUpdateSuccess',
    sendEmail: 'sendEmail',
    forgotPassword: 'forgotPassword',
    successPassword: 'successPassword',
    loginDelay: 'loginDelay',
    forgotPwdRedirect: 'forgotPwdRedirect',
    showNotification: 'showNotification',
    hideNotification: 'hideNotification',
    changeLanguage: 'changeLanguage',
    goBack: 'goBack',
    getSupportedlanguageDetails: 'getSupportedlanguageDetails',
    getEmbargoCountryDetails: 'getEmbargoCountryDetails',
    backToSigninFlag: 'backToSigninFlag',
    updateDeepLinkApplicationsList: 'updateDeepLinkApplicationsList',
    updateAppMount: 'updateAppMount',
    browserIEcheck: 'browserIEcheck',
}
export const authReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.login:
            return {
                ...state,
                loginAuth: true,
                updateEmailAuth: false,
                verifyEmailAuth: false,
                logoutAuth: false,
                emailVerificationSuccessAuth: true,
                changePasswordAuth: false,
                emailUpdateSuccessAuth: true,
                sendEmailAuth: true,
                forgotPasswordAuth: false,
                successPasswordAuth: false,
                loginDelayAuth: false,
                forgotPwdRedirectAuth: false
            }
        case ACTIONS.sendEmail:
            return {
                ...state,
                loginAuth: true,
                updateEmailAuth: false,
                verifyEmailAuth: false,
                logoutAuth: false,
                emailVerificationSuccessAuth: false,
                changePasswordAuth: false,
                emailUpdateSuccessAuth: false,
                sendEmailAuth: true,
                forgotPasswordAuth: false,
                successPasswordAuth: false,
                loginDelayAuth: false,
                forgotPwdRedirectAuth: false
            }
        case ACTIONS.updateEmail:
            return {
                ...state,
                loginAuth: true,
                verifyEmailAuth: false,
                logoutAuth: false,
                emailVerificationSuccessAuth: false,
                changePasswordAuth: false,
                updateEmailAuth: true,
                sendEmailAuth: false,
                emailUpdateSuccessAuth: true,
                forgotPasswordAuth: false,
                successPasswordAuth: false,
                loginDelayAuth: false,
                forgotPwdRedirectAuth: false
            }
        case ACTIONS.verifyEmail:
            return {
                ...state,
                loginAuth: true,
                updateEmailAuth: false,
                logoutAuth: false,
                emailVerificationSuccessAuth: false,
                changePasswordAuth: false,
                verifyEmailAuth: true,
                emailUpdateSuccessAuth: false,
                forgotPasswordAuth: false,
                successPasswordAuth: false,
                loginDelayAuth: false,
                forgotPwdRedirectAuth: false
            }
        case ACTIONS.emailverificationsuccess:
            return {
                ...state,
                loginAuth: true,
                updateEmailAuth: false,
                verifyEmailAuth: false,
                logoutAuth: false,
                changePasswordAuth: false,
                emailVerificationSuccessAuth: true,
                emailUpdateSuccessAuth: false,
                sendEmailAuth: false,
                forgotPasswordAuth: false,
                successPasswordAuth: false,
                loginDelayAuth: false,
                forgotPwdRedirectAuth: false
            }
        case ACTIONS.emailupdatesuccess:
            return {
                ...state,
                loginAuth: true,
                updateEmailAuth: false,
                verifyEmailAuth: false,
                logoutAuth: false,
                changePasswordAuth: false,
                emailVerificationSuccessAuth: false,
                emailUpdateSuccessAuth: true,
                sendEmailAuth: false,
                forgotPasswordAuth: false,
                successPasswordAuth: false,
                loginDelayAuth: false,
                forgotPwdRedirectAuth: false
            }
        case ACTIONS.logout:
            return {
                ...state,
                loginAuth: true,
                updateEmailAuth: false,
                verifyEmailAuth: false,
                emailVerificationSuccessAuth: false,
                changePasswordAuth: false,
                logoutAuth: true,
                emailUpdateSuccessAuth: false,
                sendEmailAuth: false,
                forgotPasswordAuth: false,
                successPasswordAuth: false,
                loginDelayAuth: false,
                forgotPwdRedirectAuth: false
            }
        case ACTIONS.changePassword:
            return {
                ...state,
                loginAuth: true,
                updateEmailAuth: false,
                verifyEmailAuth: false,
                logoutAuth: false,
                emailVerificationSuccessAuth: false,
                changePasswordAuth: true,
                emailUpdateSuccessAuth: false,
                sendEmailAuth: false,
                forgotPasswordAuth: false,
                successPasswordAuth: false,
                loginDelayAuth: false,
                forgotPwdRedirectAuth: false
            }
        case ACTIONS.forgotPassword:
            return {
                ...state,
                loginAuth: true,
                updateEmailAuth: false,
                verifyEmailAuth: false,
                logoutAuth: false,
                emailVerificationSuccessAuth: false,
                changePasswordAuth: false,
                emailUpdateSuccessAuth: false,
                sendEmailAuth: false,
                forgotPasswordAuth: true,
                successPasswordAuth: false,
                loginDelayAuth: false,
                forgotPwdRedirectAuth: false
            }
        case ACTIONS.successPassword:
            return {
                ...state,
                loginAuth: true,
                updateEmailAuth: false,
                verifyEmailAuth: false,
                logoutAuth: false,
                emailVerificationSuccessAuth: false,
                changePasswordAuth: false,
                emailUpdateSuccessAuth: false,
                sendEmailAuth: false,
                forgotPasswordAuth: false,
                successPasswordAuth: true,
                loginDelayAuth: false,
                forgotPwdRedirectAuth: false
            }
        case ACTIONS.loginDelay:
            return {
                ...state,
                loginAuth: true,
                updateEmailAuth: false,
                verifyEmailAuth: false,
                logoutAuth: false,
                emailVerificationSuccessAuth: false,
                changePasswordAuth: false,
                emailUpdateSuccessAuth: false,
                sendEmailAuth: false,
                forgotPasswordAuth: false,
                successPasswordAuth: false,
                loginDelayAuth: true,
                forgotPwdRedirectAuth: false
            }
        case ACTIONS.forgotPwdRedirect:
            return {
                ...state,
                loginAuth: true,
                updateEmailAuth: false,
                verifyEmailAuth: false,
                logoutAuth: false,
                emailVerificationSuccessAuth: false,
                changePasswordAuth: false,
                emailUpdateSuccessAuth: false,
                sendEmailAuth: false,
                forgotPasswordAuth: false,
                successPasswordAuth: false,
                loginDelayAuth: false,
                forgotPwdRedirectAuth: true
            }
        case ACTIONS.showNotification:
            return {
                 ...state,
                 registrationSuccessNotification: true,
            }
        case ACTIONS.hideNotification:
            return {
                 ...state,
                 registrationSuccessNotification: false,
            }
        case ACTIONS.goBack:
            return {
                 ...state,
                 goBackUpdateComponent: action.data,
            }
        case ACTIONS.getSupportedlanguageDetails:
            return {
                 ...state,
                 supportedLanguagesList: action.data?.supportedLanguagesList,
                 supportedLanguagesListDetails: action.data?.supportedLanguagesListDetails,
                 supportedHeaderFooterList: action.data?.supportedHeaderFooterList,
            }
        case ACTIONS.getEmbargoCountryDetails:
            return {
                 ...state,
                 embargoCountryList: action.data?.embargoCountryList,
            }
        case ACTIONS.backToSigninFlag:
            return {
                 ...state,
                 backToSigninFlag: action.data,
                 signupLanguage: action.signupLanguage,
            }
        case ACTIONS.updateDeepLinkApplicationsList:
            return {
                 ...state,
                 deepLinkApplicationsList: action.data?.list,
            }
        case ACTIONS.updateAppMount:
            return {
                 ...state,
                 isAppMounted: action.data,
            }
        case ACTIONS.browserIEcheck:
            return {
                 ...state,
                 isIE: action.isIE,
            }
        case ACTIONS.changeLanguage:
            return {
                ...state,
                selectedLanguage: action.lang,
                languageCode: action.languageCode ? action.languageCode : 'en',
                isBrowserDefaultLang: action?.isBrowserDefaultLang || false,
                content: {
                    ...state.content,
                    login_title: action.data.form.element_label.login_title,
                    // company_subtext: action.data.page_content.sign_up.label.company_subtext,
                    userID_email_label: action.data.form.element_label.userID_email_label,
                    userID_email_ph: action.data.form.placeholder_text.userID_email_ph,
                    remember_me_label: action.data.form.element_label.remember_me_label,
                    next: action.data.form.element_label.next,
                    need_help_signin: action.data.form.element_label.need_help_signin,
                    dont_have_an_account: action.data.form.element_label.dont_have_an_account,
                    signUp: action.data.form.element_label.signUp,
                    required_field: action.data.form.element_label.required_field,
                    header_appname: action.data.form.element_label.header_appname,
                    login_btn: action.data.general.cta.login_btn,
                    login_update_title: action.data.form.element_label.login_update_title,
                    login_update_btn: action.data.general.cta.login_update_btn,
                    password_label: action.data.form.element_label.password_label,
                    password_ph: action.data.form.element_label.password_ph,
                    forgot_pwd_link_text: action.data.form.display_message_text.forgot_pwd_link_text,
                    forgot_pwd_title: action.data.form.element_label.forgot_pwd_title,
                    forgot_pwd_text: action.data.form.display_message_text.forgot_pwd_text,
                    email_label: action.data.form.element_label.email_label,
                    email_ph: action.data.form.placeholder_text.email_ph,
                    password_reset_btn: action.data.general.cta.password_reset_btn,
                    successful_logout_title: action.data.form.element_label.successful_logout_title,
                    successful_logout_text: action.data.form.element_label.successful_logout_text,
                    return_to_login_btn: action.data.general.cta.return_to_login_btn,
                    verify_email_title: action.data.form.element_label.verify_email_title,
                    verify_email_text: action.data.form.element_label.verify_email_text,
                    userID_label: action.data.form.element_label.userID_label,
                    update_email_btn: action.data.general.cta.update_email_btn,
                    no_update_email_btn: action.data.general.cta.no_update_email_btn,
                    verify_email_btn: action.data.general.cta.verify_email_btn,
                    email_correct_question: action.data.form.display_message_text.email_correct_question,
                    login_delayed_title: action.data.form.element_label.login_delayed_title,
                    login_delayed_text: action.data.form.display_message_text.login_delayed_text,
                    change_pwd_redirect_title: action.data.form.element_label.change_pwd_redirect_title,
                    successful_change_password_title: action.data.form.element_label.successful_change_password_title,
                    successful_change_password_text: action.data.form.display_message_text.successful_change_password_text,
                    successful_email_verify_title: action.data.form.element_label.successful_email_verify_title,
                    successful_email_verify_text: action.data.form.display_message_text.successful_email_verify_text,
                    successful_email_update_title: action.data.form.element_label.successful_email_update_title,
                    successful_email_update_text: action.data.form.display_message_text.successful_email_update_text,
                    sent_email_title: action.data.form.element_label.sent_email_title,
                    sent_email_text: action.data.form.display_message_text.sent_email_text,
                    back_to_login_btn: action.data.general.cta.back_to_login_btn,
                    valid_email_inline_error: action.data.form.functional_error_message.valid_email_inline_error,
                    account_locked_message: action.data.form.functional_error_message.account_locked_message,
                    update_email_title: action.data.form.element_label.update_email_title,
                    new_email_label: action.data.form.element_label.new_email_label,
                    confirm_new_email_label: action.data.form.element_label.confirm_new_email_label,
                    cancel_btn: action.data.general.cta.cancel_btn,
                    save_btn: action.data.general.cta.save_btn,
                    page_not_found_title: action.data.form.element_label.page_not_found_title,
                    expire_pwd_title: action.data.form.element_label.expire_pwd_title,
                    curr_pwd_label: action.data.form.element_label.curr_pwd_label,
                    curr_pwd_ph: action.data.form.element_label.curr_pwd_ph,
                    new_pwd_label: action.data.form.element_label.new_pwd_label,
                    new_pwd_ph: action.data.form.element_label.new_pwd_ph,
                    confirm_pwd_label: action.data.form.element_label.confirm_pwd_label,
                    confirm_pwd_ph: action.data.form.element_label.confirm_pwd_ph,
                    change_pwd_btn: action.data.general.cta.change_pwd_btn,
                    expire_password_nomatch: action.data.form.validation_error_message.expire_password_nomatch,
                    same_password_error: action.data.form.functional_error_message.same_password_error,
                    invalid_credentials_error: action.data.form.functional_error_message.invalid_credentials_error,
                    email_mismatch_error: action.data.form.functional_error_message.email_mismatch_error,
                    email_already_inuse_error: action.data.form.functional_error_message.email_already_inuse_error,
                    same_email_error: action.data.form.functional_error_message.same_email_error,
                    password_already_used_message: action.data.form.functional_error_message.password_already_used_message,
                    change_password_failed_message: action.data.form.functional_error_message.change_password_failed_message,
                    password_requirements: action.data.form.element_label.password_requirements,
                    okta_user_locked: action.data.form.validation_error_message.okta_user_locked,
                    page_not_found_text: action.data.form.display_message_text.page_not_found_text,
                    system_error: action.data.form.functional_error_message.system_error,
                    email_verification_error: action.data.form.functional_error_message.email_verification_error,
                    forgotPassword: action.data.form.display_message_text.forgot_pwd_link_text,
                    unlockAccount: action.data.form.display_message_text.unlockAccount,
                    help: action.data.form.element_label.help,
                    contactAdmin: action.data.form.display_message_text.contactAdmin,
                    user_not_registered: action.data.form.validation_error_message.user_not_registered,
                    inActive_user: action.data.form.validation_error_message.inActive_user,
                    user_id_length_error: action.data.form.functional_error_message.user_id_length_error,
                    jit_create_delay_message: action.data.form.display_message_text.jit_create_delay_message,
                    password_expired_message: action.data.form.display_message_text.password_expired_message,
                    ie_browser_message: action.data.form.display_message_text.ie_browser_message,
                    user_name_validation: action.data.form.validation_error_message.user_name_validation,
                    reset_password: action.data.form.element_label.reset_password,
                    email_id: action.data.form.element_label.email_id,
                    back_to_sign_in: action.data.form.element_label.back_to_sign_in,
                    update_email_error_message: action.data.form.display_message_text.update_email_error_message,
                    forget_user_id: action.data.form.display_message_text.forget_user_id,
                    email_update_with_user_id: action.data.form.display_message_text.email_update_with_user_id,
                    unlock_account_tip_message_1: action.data.form.display_message_text.unlock_account_tip_message_1,
                    unlock_account_tip_message_2: action.data.form.display_message_text.unlock_account_tip_message_2,
                    get_help_label: action.data.form.display_message_text.get_help_label,
                    signup_complete_notification: action.data.form.display_message_text.signup_complete_notification,
                    gt_evaluation_failed: action.data.form.functional_error_message.gt_evaluation_failed,
                    gt_evaluation_system_error: action.data.form.functional_error_message.gt_evaluation_system_error,
                    block_page: action.data.form.functional_error_message.block_page,
                    contact_hpe_support_label: action.data.form.functional_error_message.contact_hpe_support_label,
                    block_page_success_message_header: action.data.form.functional_error_message.block_page_success_message_header,
                    block_page_success_message_text: action.data.form.functional_error_message.block_page_success_message_text,
                    block_page_blocked_message_header: action.data.form.functional_error_message.block_page_blocked_message_header,
                    block_page_blocked_message_text: action.data.form.functional_error_message.block_page_blocked_message_text,
                    block_page_error_message_header: action.data.form.functional_error_message.block_page_error_message_header,
                    block_page_error_message_text: action.data.form.functional_error_message.block_page_error_message_text,
                    gts_blocked_error_message: action.data.form.functional_error_message.gts_blocked_error_message,
                    gts_system_error_message: action.data.form.functional_error_message.gts_system_error_message,
                    custom_error_page_title: action.data.form.functional_error_message.custom_error_page_title,
                    custom_error_page_subtitle: action.data.form.functional_error_message.custom_error_page_subtitle,
                    click_here_text: action.data.form.functional_error_message.click_here_text,
                    activation_redirect_message: action.data.form.functional_error_message.activation_redirect_message,
                    emailmask_your_email: action.data.form.placeholder_text.emailmask_your_email,
                    emailmask_company: action.data.form.placeholder_text.emailmask_company,
                    reset_pwd_btn: action.data.general.cta.reset_pwd_btn,
                    ie_browser_info: action.data.form.display_message_text.ie_browser_info,
                    ie_browser_info_2: action.data.form.display_message_text.ie_browser_info_2,
                },
                
                signUp: action.data.page_content.sign_up.label || {},
                password_validation: action.data.page_content.sign_up.password_validation || {},
                HPE_privacy_notice: action.data.page_content.sign_up.hpe_privacy_notice || {},
                signupValidation: action.data.page_content.sign_up.signupValidation || {},
                registration_Password_Rules: action.data.page_content.sign_up.registration_Password_Rules || {},
                passwordRequirements: action.data.general.password_requirment || {},
                complete_site_down: action.data.page_content.complete_site_down,
                countryList: action.data.countryList || [],
                timezoneList: action.data.timezoneList || []
            };

        default:
            return state
    }
}