import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Form,
  FormField,
  Header,
  ResponsiveContext,
  Text,
  TextInput,
  Grommet
} from 'grommet';
import { FormNext } from 'grommet-icons';
import { hpe } from 'grommet-theme-hpe';
import { getData } from '../../Data/fetchData';
import { AuthContext } from '../../AuthContext';

const ResetPassword = () => {
  const [formValues, setFormValues] = useState({
    emailAddress: '',
  });
  const size = useContext(ResponsiveContext);
  const { state } = useContext(AuthContext)
  const { reset_password } = state.content;

  // eslint-disable-next-line no-unused-vars
  const onSubmit = async({ value, touched }) => {
    let {resetPasswordUrl} = await getData(`/v1-password-reset/${formValues.emailAddress}`)
    console.log(resetPasswordUrl)
  };


  return (
    <Grommet theme={hpe}>
    <Box
        flex
        gap="medium"
        width="medium"
        className="form-container"
        alignContent='center'
      >
        <Header
          direction="column"
          align="start"
          gap="xxsmall"
          pad={{ horizontal: 'xxsmall' }}
          className="form-header"
        >
        <Text size="xxlarge" weight="bold">
          {reset_password}
        </Text>
      </Header>
      <Box
        // Padding used to prevent focus from being cutoff
        pad={{ horizontal: 'xxsmall' }}
      >
        <Form validate="blur"
          value={formValues}
          onChange={setFormValues}
          onSubmit={({ value, touched }) => onSubmit({ value, touched })}
          method="post">
            <FormField
            htmlFor="emailAddress"
            name="emailddress"
            label="Email Address"
          >
            <TextInput
              id="emailAddress"
              name="emailAddress"
              placeholder="Email Address"
              type="email address"
            />
            </FormField>
            <Box
              align={size !== 'small' ? 'end' : undefined}
              margin={{ top: 'medium', bottom: 'small' }}
            >
              <Button
                label="Reset Password"
                icon={<FormNext />}
                reverse
                primary
                type="submit"
                className="submit-btn"
              />
            </Box>
          </Form>
        {/* <Form
          validate="blur"
          value={formValues}
          onChange={setFormValues}
          onSubmit={({ value, touched }) => onSubmit({ value, touched })}
          method="post"
        >
          <FormField
            htmlFor="currentPassword"
            name="currentPassword"
            label="Current Password"
          >
            <TextInput
              id="currentPassword"
              name="currentPassword"
              placeholder="Current password"
              type="password"
            />
          </FormField>
          <FormField
            htmlFor="newPassword"
            name="newPassword"
            label="New Password"
            validate={passwordRulesWeak}
          >
            <TextInput
              id="newPassword"
              name="newPassword"
              placeholder="Enter new password"
              type="password"
            />
          </FormField>
          <FormField
            htmlFor="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            validate={confirmPassword}
          >
            <TextInput
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm new password"
              type="password"
            />
          </FormField>
          <Box
            align={size !== 'small' ? 'end' : undefined}
            margin={{ top: 'medium', bottom: 'small' }}
          >
            <Button label="Change Password" primary type="submit" reverse />
          </Box>
        </Form> */}
        
      </Box>
    </Box>
  </Grommet>
  )
}

export default ResetPassword
