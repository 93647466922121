export const applicationUrl = process.env.REACT_APP_APPLICATION_URL;
export const enviroment = process.env.REACT_APP_ENV;
export const gtContactHpeSupportUrl = process.env.REACT_APP_GT_CONTACT_HPE_SUPPORT;

export const appUrl = `${applicationUrl}/hpe/cf/sessionRedirect`
export const url = `${applicationUrl}/app/UserHome/`
export const errorUrl = `https://www.hpe.com/us/en/errorpages/500.html`
export const notFoundUrl = `https://www.hpe.com/us/en/errorpages/404.html`
export const redirectUrlString = `&redirectUrl=`
export const hpeDomainLoginUrl = `${applicationUrl}`
export const oktaAppHome = 'https://www.hpe.com/us/en/home.html'
export const webFingerUrl = `${applicationUrl}/.well-known/webfinger?resource=okta:acct:`
export const unlockUrl = `${applicationUrl}/signin/unlock`
export const helpUrl = `${applicationUrl}/messages/help`
export const helpUrlCommon = `${applicationUrl}/messages/help/help.html`
export const registerUrl = `${applicationUrl}/signin/register`
export const sessionCookieRedirectUrl = `${applicationUrl}/login/sessionCookieRedirect?token=`
export const logoutUrl = `${applicationUrl}/hpe/cf/logout`
export const centralFormUrl = `${hpeDomainLoginUrl}/hpe/cf/`
export const blockPageUrl = `${centralFormUrl}blockpage`
export const translationsUrl = `${hpeDomainLoginUrl}/messages`
export const supportedLanguagesListUrl = `${hpeDomainLoginUrl}/messages/supportedLanguagesList.json`
export const embargoCountryListUrl = `${hpeDomainLoginUrl}/messages/embargoCountryList.json`
export const deepLinkApplicationsListUrl = `${hpeDomainLoginUrl}/messages/deepLinkApplicationsList.json`
export const stylesUrl = `${hpeDomainLoginUrl}/messages/styles/styles.css`
export const change_pwd_path = `/change-password`
export const login_path = `/login`
export const verify_email_path = `/verify-email`
export const email_verification_success_path = `/email-verification-success`
export const update_email_path = `/update-email`
export const update_email_success_path= `/email-update-success`
export const send_email_path = `send-email`
export const success_component_path = `/success`
export const forgot_pwd_redirect_path = `/forgot-password-redirect`
export const contact_HPE_support = `https://h41390.www4.hpe.com/support/index.html?form=loginsupport`
export const hpeTermsOfUseUrl = ({lang, cc}) => `https://www.hpe.com/${cc}/${lang}/about/legal/terms-of-use.html`
export const hpePrivacyNoticeUrl = ({lang, cc}) => `https://www.hpe.com/${cc}/${lang}/legal/privacy.html`
export const headerFooterScriptUrl = (cc, lang) =>
  `https://h50007.www5.hpe.com/hfws/${cc}/${lang}/hpe/latest.r/root?contentType=js&switchToCHF=true&hide_country_selector=true&hide_main_nav=true&hide_search=true&hide_cart=true&hide_sign_in=true&hide_help_menu=false&hide_cube_menu=true&header_logo=hpe&slim_footer=true`;
export const okta_user_lang_cookie_domain = enviroment // itg env: 'itg' and prod env: 'prod', 
export const gt_contact_HPE_support = gtContactHpeSupportUrl
export const signupActivateRedirectUrl = `${centralFormUrl}activate-redirect`
export const helpURL_dev_itg = "https://ext-itg.support.hpe.com/hpesc/public/docDisplay?docId=sd00003196en_us";
export const helpURL_prod = "https://support.hpe.com/hpesc/public/docDisplay?docId=sd00003196en_us";
export const header_helpIcon_dissable = ({isIEcheck}) => `https://h50007.www5.hpe.com/hfws/us/en/hpe/latest.r/root?contentType=js&switchToCHF=true&hide_country_selector=true&hide_main_nav=true&hide_search=true&hide_cart=true&hide_sign_in=true&hide_help_menu=${isIEcheck}&hide_cube_menu=true&header_logo=hpe&slim_footer=true`;
export const chinaPolicyLinkChinese = "https://www.hpe.com/cn/zh/about/legal/consent-letter.html";
export const chinaPolicyLinkEnglish = "https://www.hpe.com/us/en/about/legal/consent-letter.html";