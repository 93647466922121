import { Header, Text } from "grommet";

const Title = (props) => {
  const { title, subTitle } = props.info;
  return (
    <Header
      direction="column"
      align="start"
      gap="xxsmall"
      pad={{ horizontal: "xxsmall" }}
      className="form-header"
    >
      <Text size="3xl" weight="bold">
        {title}
      </Text>
      {subTitle && <Text>{subTitle}</Text>}
    </Header>
  );
};

export default Title;
