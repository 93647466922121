import React, { useContext } from 'react';
import {
  Grommet,
  Box,
  Button,
  Header,
  ResponsiveContext,
  Text
} from 'grommet';
import { FormNext } from 'grommet-icons';
import { hpe } from 'grommet-theme-hpe';
import { content } from '../../Helpers/content';
import { useHistory } from 'react-router';
import { AuthContext } from '../../AuthContext';

const Logout = () => {
  const size = useContext(ResponsiveContext);
  const { state } = useContext(AuthContext)
  const history = useHistory()
  const {successful_logout_title,successful_logout_text, return_to_login_btn} = state.content
  const handleLogout = () => {
    history.push('/')
  }
  return (
    <Grommet theme={hpe}>
      <Box
        flex
        gap="medium"
        width="medium"
        className="form-container"
        alignContent='center'
      >
        <Header
          direction="column"
          align="start"
          gap="xxsmall"
          pad={{ horizontal: 'xxsmall' }}
          className="form-header"
        >
          <Text size="xxlarge" weight="bold">
            {successful_logout_title}
          </Text>
        </Header>
        <Text size="small" weight="normal">
          {successful_logout_text}
        </Text>
        <Box
          // Padding used to prevent focus from being cutoff
          pad={{ horizontal: 'xxsmall' }}
        >
        </Box>
        <Box
          align={size !== 'small' ? 'end' : undefined}
          margin={{ top: 'medium', bottom: 'small' }}
        >
          <Button
            label={return_to_login_btn}
            icon={<FormNext />}
            reverse
            primary
            type="button"
            onClick={handleLogout}
          />
        </Box>
      </Box>
    </Grommet>
  );
};

export default Logout


