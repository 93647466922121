import React, { useContext, useState } from 'react';
import { Grommet, Box, Button, Form, FormField, MaskedInput, Header, Text } from 'grommet';
import { hpe } from 'grommet-theme-hpe';
import { FormNext } from 'grommet-icons';
import { fetchData, fetchDataAxios } from '../../Data/fetchData';
import { loginUpdatePayload } from '../../Helpers/requestsPayload';
import { useLocation, useHistory } from 'react-router';
import { constants } from '../../Helpers/content';
import { AuthContext } from '../../AuthContext';
import { ACTIONS } from '../../AuthReducer'
import AlertBanner from '../AlertBanner';
import { update_email_success_path, verify_email_path, email_verification_success_path, contact_HPE_support } from '../../Helpers/urls';
import { login_update_endpoint } from '../../Helpers/endpoints';
import Title from "../header/Title";
import { formatAnchorTag } from "../../Helpers/common";

const UpdateEmail = () => {
  const [formValues, setFormValues] = useState({
    newEmail: '',
    confirmNewEmail: ''
  });

  const [credentialError, setCredentialError] = useState(false)
  const [message, setMessage] = useState('')
  const location = useLocation()
  const history = useHistory()
  const { state, dispatch } = useContext(AuthContext)
  const { email, password, sessionToken, userId } = location.state
  const onChange = values => {
    setFormValues(values);
  };
  const {
    same_email_error,
    email_already_inuse_error,
    exception,
    changeEmailResponse,
    system_error,
    update_email_btn,
    email_mismatch_error,
    update_email_title,
    new_email_label,
    confirm_new_email_label,
    cancel_btn,
    update_email_error_message,
    email_verification_error,
    required_field,
    login_title,
    verify_email_text,
    contact_hpe_support_label,
    valid_email_inline_error,
  } = state.content;

    const emailValidation = [
      {
        regexp: new RegExp('[^@ \\t\\r\\n]+@'),
        message: valid_email_inline_error,
        status: 'error',
      },
      {
        regexp: new RegExp('[^@ \\t\\r\\n]+@[^@ \\t\\r\\n]+\\.[^@ \\t\\r\\n]+'),
        message: valid_email_inline_error,
        status: 'error',
      },
      {
        regexp: new RegExp('[^@ \\t\\r\\n]+@[^@ \\t\\r\\n]+\\.[^@ \\t\\r\\n]+'),
        message: valid_email_inline_error,
        status: 'error',
      },
    ];
  
     const emailMask = [
      {
        regexp: /^[\w\-_.+]+$/,
        placeholder: state.content.emailmask_your_email,
      },
      { fixed: '@' },
      {
        regexp: /^[\w]+$/,
        placeholder: state.content.emailmask_company,
      },
      // { fixed: '.' },
      {
        regexp: /^[\w.]+$/,
        placeholder: '.com',
      },
    ];

  const { success } = constants;

  // eslint-disable-next-line no-unused-vars
  const onSubmit = ({ value, touched }) => {
    // submission logic
    updateEmail()
  };

  const onCancel = () => {
    dispatch({ type: ACTIONS.verifyEmail })
    history.push({ pathname: verify_email_path, state: { sessionToken, email, userId, password } })
    return
  }

  const formatContactHpeSupportLink = (message) =>
    formatAnchorTag(message, contact_hpe_support_label, contact_HPE_support);

  const updateEmail = async () => {
    if (location.state.email.toLowerCase() === formValues.newEmail.toLowerCase()) {
      setCredentialError(true)
      setMessage(same_email_error)
    } else {
      if (formValues.newEmail === formValues.confirmNewEmail) {
        const data = await fetchDataAxios(login_update_endpoint, loginUpdatePayload(formValues.newEmail, location.state))
        if (Object.keys(data).indexOf('oktaResponse') !== -1) {
          setCredentialError(true)
          setMessage(formatContactHpeSupportLink(email_verification_error))
        } else if (Object.keys(data).indexOf(exception) !== -1) {
          setCredentialError(true)
          // setMessage(data.exception.faults[0].faultMessage)
          setMessage(update_email_error_message)
        } else if (Object.keys(data).indexOf(exception) === -1) {
          if (data && data.changeUserIdResponse && data.changeUserIdResponse.status && data.changeUserIdResponse.status === success) {
            dispatch({ type: ACTIONS.updateEmail })
            history.push(update_email_success_path)
            return
          } else if (data && data.changeEmailResponse && data.changeEmailResponse.status && data.changeEmailResponse.status === success) {
            dispatch({type:ACTIONS.emailverificationsuccess})
            history.push(email_verification_success_path)
            return
          } 
        }
        else if (Object.keys(data).indexOf(changeEmailResponse) !== -1) {
          setCredentialError(true)
          setMessage(update_email_error_message)
        }
        else {
          setCredentialError(true)
          setMessage(system_error)
        }

      } else {
        setCredentialError(true)
        setMessage(email_mismatch_error)
      }
    }
  }

  return (
    <Grommet theme={hpe}>
      <Box
        flex
        gap="xxsmall"
        width="medium"
        className="form-container"
        alignContent="center"
      >
        <Title
          info={{ title: `HPE ${login_title}`, subTitle: verify_email_text }}
        />
        <Header
          direction="column"
          align="start"
          gap="xxsmall"
          pad={{ horizontal: "xxsmall" }}
          className="form-header"
          margin={{ top: "small"}}
        >
          <Text size="xlarge" weight="bold">
            {update_email_title}
          </Text>
        </Header>

        <Form
          validate="blur"
          value={formValues}
          messages={{
            required: required_field,
          }}
          onChange={(nextValue) => onChange(nextValue)}
          onSubmit={({ value, touched }) => onSubmit({ value, touched })}
          method="post"
        >
          {credentialError && <AlertBanner message={message} />}
          <FormField
            label={new_email_label}
            htmlFor="new-email"
            name="newEmail"
            validate={emailValidation}
            required
          >
            <MaskedInput
              id="new-email"
              name="newEmail"
              mask={emailMask}
              type="email"
            />
          </FormField>

          <FormField
            label={confirm_new_email_label}
            htmlFor="confirm-new-email"
            name="confirmNewEmail"
            validate={emailValidation}
            required
          >
            <MaskedInput
              id="confirm-new-email"
              name="confirmNewEmail"
              mask={emailMask}
              type="email"
            />
          </FormField>
          <Box
            align="start"
            margin={{ top: "medium", bottom: "small" }}
            fill="horizontal"
            direction="row"
            gap="medium"
            justify="start"
          >
            <Button
              label={update_email_btn}
              // icon={<FormNext />}
              style={{ fontSize: "15px"}}
              reverse
              primary
              type="submit"
              className="submit-btn"
            />
            <Button
              label={cancel_btn}
              reverse
              style={{ fontSize: "15px"}}
              secondary
              type="button"
              onClick={() => onCancel()}
              className="submit-btn"
            />
          </Box>
        </Form>
      </Box>
    </Grommet>
  );
};

export default UpdateEmail