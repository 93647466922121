export const supportedLanguagesList = [
  { label: "English", value: "en", cc: "us" },
  // { label: "Français", value: "fr", cc: "fr" },
  // { label: "Português", value: "pt", cc: "br" },
  // { label: "Deutsch", value: "de", cc: "de" },
  // { label: "Русский", value: "ru", cc: "ru" },
  { label: "Español", value: "es", cc: "es" },
  // { label: "Italiano", value: "it", cc: "it" },
  { label: "日本語", value: "ja", cc: "jp" },
  // { label: "한국어", value: "kr", cc: "us" },
  // { label: "简体中文", value: "zh", cc: "cn" },
  // { label: "Türkçe", value: "tr", cc: "tr" },
  // { label: "Bahasa Indonesia", value: "id", cc: "us" },
];

export const supportedHeaderFooterList = [
  "ae/ar",
  "ae/en",
  "asia_pac/en",
  "at/de",
  "au/en",
  "be/en",
  "be/fr",
  "be/nl",
  "br/pt",
  "ca/en",
  "ca/fr",
  "ch/de",
  "ch/fr",
  "cn/zh",
  "cz/en",
  "de/de",
  "dk/en",
  "emea_africa/en",
  "emea_africa/fr",
  "emea_europe/en",
  "emea_middle_east/ar",
  "emea_middle_east/en",
  "es/es",
  "fi/en",
  "fr/fr",
  "hk/en",
  "ie/en",
  "il/en",
  "in/en",
  "it/it",
  "jp/ja",
  "kr/ko",
  "lamerica/en",
  "lamerica/es",
  "mx/es",
  "my/en",
  "nl/nl",
  "no/en",
  "nz/en",
  "pl/pl",
  "pt/en",
  "ru/ru",
  "sa/ar",
  "sa/en",
  "se/sv",
  "sg/en",
  "tr/tr",
  "tw/zh",
  "uk/en",
  "us/en",
  "za/en",
];

export const countryList = [
  {
    label: "Afghanistan",
    value: "af",
  },
  {
    label: "Aland Islands",
    value: "ax",
  },
  {
    label: "Albania",
    value: "al",
  },
  {
    label: "Algeria",
    value: "dz",
  },
  {
    label: "American Samoa",
    value: "as",
  },
  {
    label: "Andorra",
    value: "ad",
  },
  {
    label: "Angola",
    value: "ao",
  },
  {
    label: "Anguilla",
    value: "ai",
  },
  {
    label: "Antarctica",
    value: "aq",
  },
  {
    label: "Antigua and Barbuda",
    value: "ag",
  },
  {
    label: "Argentina",
    value: "ar",
  },
  {
    label: "Armenia",
    value: "am",
  },
  {
    label: "Aruba",
    value: "aw",
  },
  {
    label: "Australia",
    value: "au",
  },
  {
    label: "Austria",
    value: "at",
  },
  {
    label: "Azerbaijan",
    value: "az",
  },
  {
    label: "Bahamas",
    value: "bs",
  },
  {
    label: "Bahrain",
    value: "bh",
  },
  {
    label: "Bangladesh",
    value: "bd",
  },
  {
    label: "Barbados",
    value: "bb",
  },
  {
    label: "Belarus",
    value: "by",
  },
  {
    label: "Belgium",
    value: "be",
  },
  {
    label: "Belize",
    value: "bz",
  },
  {
    label: "Benin",
    value: "bj",
  },
  {
    label: "Bermuda",
    value: "bm",
  },
  {
    label: "Bhutan",
    value: "bt",
  },
  {
    label: "Bolivia",
    value: "bo",
  },
  {
    label: "Bosnia and Herzegovina",
    value: "ba",
  },
  {
    label: "Botswana",
    value: "bw",
  },
  {
    label: "Brazil",
    value: "br",
  },
  {
    label: "British Indian Ocean Territory",
    value: "io",
  },
  {
    label: "British Virgin Islands",
    value: "vg",
  },
  {
    label: "Brunei",
    value: "bn",
  },
  {
    label: "Bulgaria",
    value: "bg",
  },
  {
    label: "Burkina Faso",
    value: "bf",
  },
  {
    label: "Burundi",
    value: "bi",
  },
  {
    label: "Cambodia",
    value: "kh",
  },
  {
    label: "Cameroon",
    value: "cm",
  },
  {
    label: "Canada",
    value: "ca",
  },
  {
    label: "Cape Verde",
    value: "cv",
  },
  {
    label: "Cayman Islands",
    value: "ky",
  },
  {
    label: "Central African Republic",
    value: "cf",
  },
  {
    label: "Chad",
    value: "td",
  },
  {
    label: "Chile",
    value: "cl",
  },
  {
    label: "China",
    value: "cn",
  },
  {
    label: "Christmas Island",
    value: "cx",
  },
  {
    label: "Cocos Islands",
    value: "cc",
  },
  {
    label: "Colombia",
    value: "co",
  },
  {
    label: "Comoros",
    value: "km",
  },
  {
    label: "Cook Islands",
    value: "ck",
  },
  {
    label: "Costa Rica",
    value: "cr",
  },
  {
    label: "Croatia",
    value: "hr",
  },
  {
    label: "Cuba",
    value: "cu",
  },
  {
    label: "Curacao",
    value: "cw",
  },
  {
    label: "Cyprus",
    value: "cy",
  },
  {
    label: "Czech Republic",
    value: "cz",
  },
  {
    label: "Democratic Republic of the Congo",
    value: "cd",
  },
  {
    label: "Denmark",
    value: "dk",
  },
  {
    label: "Djibouti",
    value: "dj",
  },
  {
    label: "Dominica",
    value: "dm",
  },
  {
    label: "Dominican Republic",
    value: "do",
  },
  {
    label: "East Timor",
    value: "tl",
  },
  {
    label: "Ecuador",
    value: "ec",
  },
  {
    label: "Egypt",
    value: "eg",
  },
  {
    label: "El Salvador",
    value: "sv",
  },
  {
    label: "Equatorial Guinea",
    value: "gq",
  },
  {
    label: "Eritrea",
    value: "er",
  },
  {
    label: "Estonia",
    value: "ee",
  },
  {
    label: "Ethiopia",
    value: "et",
  },
  {
    label: "Falkland Islands",
    value: "fk",
  },
  {
    label: "Faroe Islands",
    value: "fo",
  },
  {
    label: "Fiji",
    value: "fj",
  },
  {
    label: "Finland",
    value: "fi",
  },
  {
    label: "France",
    value: "fr",
  },
  {
    label: "French Polynesia",
    value: "pf",
  },
  {
    label: "Gabon",
    value: "ga",
  },
  {
    label: "Gambia",
    value: "gm",
  },
  {
    label: "Georgia",
    value: "ge",
  },
  {
    label: "Germany",
    value: "de",
  },
  {
    label: "Ghana",
    value: "gh",
  },
  {
    label: "Gibraltar",
    value: "gi",
  },
  {
    label: "Greece",
    value: "gr",
  },
  {
    label: "Greenland",
    value: "gl",
  },
  {
    label: "Grenada",
    value: "gd",
  },
  {
    label: "Guam",
    value: "gu",
  },
  {
    label: "Guatemala",
    value: "gt",
  },
  {
    label: "Guernsey",
    value: "gg",
  },
  {
    label: "Guinea",
    value: "gn",
  },
  {
    label: "Guinea-Bissau",
    value: "gw",
  },
  {
    label: "Guyana",
    value: "gy",
  },
  {
    label: "Haiti",
    value: "ht",
  },
  {
    label: "Honduras",
    value: "hn",
  },
  {
    label: "Hong Kong SAR",
    value: "hk",
  },
  {
    label: "Hungary",
    value: "hu",
  },
  {
    label: "Iceland",
    value: "is",
  },
  {
    label: "India",
    value: "in",
  },
  {
    label: "Indonesia",
    value: "id",
  },
  {
    label: "Iran",
    value: "ir",
  },
  {
    label: "Iraq",
    value: "iq",
  },
  {
    label: "Ireland",
    value: "ie",
  },
  {
    label: "Isle of Man",
    value: "im",
  },
  {
    label: "Israel",
    value: "il",
  },
  {
    label: "Italy",
    value: "it",
  },
  {
    label: "Ivory Coast",
    value: "ci",
  },
  {
    label: "Jamaica",
    value: "jm",
  },
  {
    label: "Japan",
    value: "jp",
  },
  {
    label: "Jersey",
    value: "je",
  },
  {
    label: "Jordan",
    value: "jo",
  },
  {
    label: "Kazakhstan",
    value: "kz",
  },
  {
    label: "Kenya",
    value: "ke",
  },
  {
    label: "Kiribati",
    value: "ki",
  },
  {
    label: "Kosovo",
    value: "xk",
  },
  {
    label: "Kuwait",
    value: "kw",
  },
  {
    label: "Kyrgyzstan",
    value: "kg",
  },
  {
    label: "Laos",
    value: "la",
  },
  {
    label: "Latvia",
    value: "lv",
  },
  {
    label: "Lebanon",
    value: "lb",
  },
  {
    label: "Lesotho",
    value: "ls",
  },
  {
    label: "Liberia",
    value: "lr",
  },
  {
    label: "Libya",
    value: "ly",
  },
  {
    label: "Liechtenstein",
    value: "li",
  },
  {
    label: "Lithuania",
    value: "lt",
  },
  {
    label: "Luxembourg",
    value: "lu",
  },
  {
    label: "Macau SAR",
    value: "mo",
  },
  {
    label: "Macedonia",
    value: "mk",
  },
  {
    label: "Madagascar",
    value: "mg",
  },
  {
    label: "Malawi",
    value: "mw",
  },
  {
    label: "Malaysia",
    value: "my",
  },
  {
    label: "Maldives",
    value: "mv",
  },
  {
    label: "Mali",
    value: "ml",
  },
  {
    label: "Malta",
    value: "mt",
  },
  {
    label: "Marshall Islands",
    value: "mh",
  },
  {
    label: "Mauritania",
    value: "mr",
  },
  {
    label: "Mauritius",
    value: "mu",
  },
  {
    label: "Mayotte",
    value: "yt",
  },
  {
    label: "Mexico",
    value: "mx",
  },
  {
    label: "Micronesia",
    value: "fm",
  },
  {
    label: "Moldova",
    value: "md",
  },
  {
    label: "Monaco",
    value: "mc",
  },
  {
    label: "Mongolia",
    value: "mn",
  },
  {
    label: "Montenegro",
    value: "me",
  },
  {
    label: "Morocco",
    value: "ma",
  },
  {
    label: "Montserrat",
    value: "ms",
  },
  {
    label: "Mozambique",
    value: "mz",
  },
  {
    label: "Myanmar",
    value: "mm",
  },
  {
    label: "Namibia",
    value: "na",
  },
  {
    label: "Nauru",
    value: "nr",
  },
  {
    label: "Nepal",
    value: "np",
  },
  {
    label: "Netherlands",
    value: "nl",
  },
  {
    label: "Netherlands Antilles",
    value: "an",
  },
  {
    label: "New Caledonia",
    value: "nc",
  },
  {
    label: "New Zealand",
    value: "nz",
  },
  {
    label: "Nicaragua",
    value: "ni",
  },
  {
    label: "Niger",
    value: "ne",
  },
  {
    label: "Nigeria",
    value: "ng",
  },
  {
    label: "Niue",
    value: "nu",
  },
  {
    label: "North Korea",
    value: "kp",
  },
  {
    label: "Northern Mariana Islands",
    value: "mp",
  },
  {
    label: "Norway",
    value: "no",
  },
  {
    label: "Oman",
    value: "om",
  },
  {
    label: "Pakistan",
    value: "pk",
  },
  {
    label: "Palau",
    value: "pw",
  },
  {
    label: "Palestine",
    value: "ps",
  },
  {
    label: "Panama",
    value: "pa",
  },
  {
    label: "Papua New Guinea",
    value: "pg",
  },
  {
    label: "Paraguay",
    value: "py",
  },
  {
    label: "Peru",
    value: "pe",
  },
  {
    label: "Philippines",
    value: "ph",
  },
  {
    label: "Pitcairn",
    value: "pn",
  },
  {
    label: "Poland",
    value: "pl",
  },
  {
    label: "Portugal",
    value: "pt",
  },
  {
    label: "Puerto Rico",
    value: "pr",
  },
  {
    label: "Qatar",
    value: "qa",
  },
  {
    label: "Republic of the Congo",
    value: "cg",
  },
  {
    label: "Reunion",
    value: "re",
  },
  {
    label: "Romania",
    value: "ro",
  },
  {
    label: "Russia",
    value: "ru",
  },
  {
    label: "Rwanda",
    value: "rw",
  },
  {
    label: "Saint Barthelemy",
    value: "bl",
  },
  {
    label: "Saint Helena",
    value: "sh",
  },
  {
    label: "Saint Kitts and Nevis",
    value: "kn",
  },
  {
    label: "Saint Lucia",
    value: "lc",
  },
  {
    label: "Saint Martin",
    value: "mf",
  },
  {
    label: "Saint Pierre and Miquelon",
    value: "pm",
  },
  {
    label: "Saint Vincent and the Grenadin",
    value: "vc",
  },
  {
    label: "Samoa",
    value: "ws",
  },
  {
    label: "San Marino",
    value: "sm",
  },
  {
    label: "Sao Tome and Principe",
    value: "st",
  },
  {
    label: "Saudi Arabia",
    value: "sa",
  },
  {
    label: "Senegal",
    value: "sn",
  },
  {
    label: "Serbia",
    value: "rs",
  },
  {
    label: "Seychelles",
    value: "sc",
  },
  {
    label: "Sierra Leone",
    value: "sl",
  },
  {
    label: "Singapore",
    value: "sg",
  },
  {
    label: "Sint Maarten",
    value: "sx",
  },
  {
    label: "Slovakia",
    value: "sk",
  },
  {
    label: "Slovenia",
    value: "si",
  },
  {
    label: "Solomon Islands",
    value: "sb",
  },
  {
    label: "Somalia",
    value: "so",
  },
  {
    label: "South Africa",
    value: "za",
  },
  {
    label: "South Korea",
    value: "kr",
  },
  {
    label: "South Sudan",
    value: "ss",
  },
  {
    label: "Spain",
    value: "es",
  },
  {
    label: "Sri Lanka",
    value: "lk",
  },
  {
    label: "Sudan",
    value: "sd",
  },
  {
    label: "Suriname",
    value: "sr",
  },
  {
    label: "Svalbard and Jan Mayen",
    value: "sj",
  },
  {
    label: "Swaziland",
    value: "sz",
  },
  {
    label: "Sweden",
    value: "se",
  },
  {
    label: "Switzerland",
    value: "ch",
  },
  {
    label: "Syria",
    value: "sy",
  },
  {
    label: "Taiwan",
    value: "tw",
  },
  {
    label: "Tajikistan",
    value: "tj",
  },
  {
    label: "Tanzania",
    value: "tz",
  },
  {
    label: "Thailand",
    value: "th",
  },
  {
    label: "Togo",
    value: "tg",
  },
  {
    label: "Tokelau",
    value: "tk",
  },
  {
    label: "Tonga",
    value: "to",
  },
  {
    label: "Trinidad and Tobago",
    value: "tt",
  },
  {
    label: "Tunisia",
    value: "tn",
  },
  {
    label: "Turkey",
    value: "tr",
  },
  {
    label: "Turkmenistan",
    value: "tm",
  },
  {
    label: "Turks and Caicos Islands",
    value: "tc",
  },
  {
    label: "Tuvalu",
    value: "tv",
  },
  {
    label: "U.S. Virgin Islands",
    value: "vi",
  },
  {
    label: "Uganda",
    value: "ug",
  },
  {
    label: "Ukraine",
    value: "ua",
  },
  {
    label: "United Arab Emirates",
    value: "ae",
  },
  {
    label: "United Kingdom",
    value: "gb",
  },
  {
    label: "United States",
    value: "us",
  },
  {
    label: "Uruguay",
    value: "uy",
  },
  {
    label: "Uzbekistan",
    value: "uz",
  },
  {
    label: "Vanuatu",
    value: "vu",
  },
  {
    label: "Vatican",
    value: "va",
  },
  {
    label: "Venezuela",
    value: "ve",
  },
  {
    label: "Vietnam",
    value: "vn",
  },
  {
    label: "Wallis and Futuna",
    value: "wf",
  },
  {
    label: "Western Sahara",
    value: "eh",
  },
  {
    label: "Yemen",
    value: "ye",
  },
  {
    label: "Zambia",
    value: "zm",
  },
  {
    label: "Zimbabwe",
    value: "zw",
  },
];

export const timezoneList = [
  {
    label: "(GMT) Greenwich Mean Time",
    value: "Etc/GMT",
  },
  {
    label: "(GMT+00:00) Dublin, Edinburgh, Lisbon, London",
    value: "Europe/London",
  },
  {
    label: "(GMT+01:00)",
    value: "Etc/GMT+1",
  },
  {
    label: "(GMT+01:00) Amsterdam, Brussels, Madrid, Paris",
    value: "Europe/Paris",
  },
  {
    label: "(GMT+01:00) Berlin, Bern, Copenhagen, Rome, Stockholm, Vienna",
    value: "Europe/Berlin",
  },
  {
    label: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    value: "Europe/Belgrade",
  },
  {
    label: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    value: "Europe/Warsaw",
  },
  {
    label: "(GMT+01:00) Casablanca",
    value: "Africa/Casablanca",
  },
  {
    label: "(GMT+02:00)",
    value: "Etc/GMT+2",
  },
  {
    label: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    value: "Europe/Helsinki",
  },
  {
    label: "(GMT+02:00) Athens, Bucharest",
    value: "Europe/Athens",
  },
  {
    label: "(GMT+02:00) Jerusalem",
    value: "Asia/Jerusalem",
  },
  {
    label: "(GMT+02:00) Cairo",
    value: "Africa/Cairo",
  },
  {
    label: "(GMT+02:00) Harare, Pretoria",
    value: "Africa/Johannesburg",
  },
  {
    label: "(GMT+03:00)",
    value: "Etc/GMT+3",
  },
  {
    label: "(GMT+03:00) Baghdad, Kuwait, Riyadh",
    value: "Asia/Riyadh",
  },
  {
    label: "(GMT+03:00) Istanbul, Minsk, Moscow, St. Petersburg, Volgograd",
    value: "Europe/Moscow",
  },
  {
    label: "(GMT+03:00) Nairobi",
    value: "Africa/Nairobi",
  },
  {
    label: "(GMT+04:00)",
    value: "Etc/GMT+4",
  },
  {
    label: "(GMT+04:00) Baku, Tbilisi, Yerevan",
    value: "Asia/Baku",
  },
  {
    label: "(GMT+04:00) Abu Dhabi, Muscat",
    value: "Asia/Dubai",
  },
  {
    label: "(GMT+04:30) Kabul",
    value: "Asia/Kabul",
  },
  {
    label: "(GMT+05:00)",
    value: "Etc/GMT+5",
  },
  {
    label: "(GMT+05:00) Yekaterinburg",
    value: "Asia/Yekaterinburg",
  },
  {
    label: "(GMT+05:00) Islamabad, Karachi, Tashkent",
    value: "Asia/Karachi",
  },
  {
    label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    value: "Asia/Kolkata",
  },
  {
    label: "(GMT+05:30) Colombo",
    value: "Asia/Colombo",
  },
  {
    label: "(GMT+05:45) Kathmandu",
    value: "Asia/Kathmandu",
  },
  {
    label: "(GMT+06:00)",
    value: "Etc/GMT+6",
  },
  {
    label: "(GMT+06:00) Almaty, Omsk",
    value: "Asia/Almaty",
  },
  {
    label: "(GMT+06:00) Dhaka",
    value: "Asia/Dhaka",
  },
  {
    label: "(GMT+06:30) Rangoon",
    value: "Asia/Rangoon",
  },
  {
    label: "(GMT+07:00)",
    value: "Etc/GMT+7",
  },
  {
    label: "(GMT+07:00) Krasnoyarsk, Novosibirsk",
    value: "Asia/Krasnoyarsk",
  },
  {
    label: "(GMT+07:00) Bangkok, Hanoi, Jakarta",
    value: "Asia/Jakarta",
  },
  {
    label: "(GMT+08:00)",
    value: "Etc/GMT+8",
  },
  {
    label: "(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Shanghai",
    value: "Asia/Shanghai",
  },
  {
    label: "(GMT+08:00) Irkutsk, Ulaanbaatar",
    value: "Asia/Irkutsk",
  },
  {
    label: "(GMT+08:00) Taipei",
    value: "Asia/Taipei",
  },
  {
    label: "(GMT+08:00) Kuala Lumpur, Singapore",
    value: "Asia/Singapore",
  },
  {
    label: "(GMT+08:00) Perth",
    value: "Australia/Perth",
  },
  {
    label: "(GMT+09:00)",
    value: "Etc/GMT+9",
  },
  {
    label: "(GMT+09:00) Yakutsk",
    value: "Asia/Yakutsk",
  },
  {
    label: "(GMT+09:00) Seoul",
    value: "Asia/Seoul",
  },
  {
    label: "(GMT+09:00) Osaka, Sapporo, Tokyo",
    value: "Asia/Tokyo",
  },
  {
    label: "(GMT+09:30) Darwin",
    value: "Australia/Darwin",
  },
  {
    label: "(GMT+09:30) Adelaide",
    value: "Australia/Adelaide",
  },
  {
    label: "(GMT+10:00)",
    value: "Etc/GMT+10",
  },
  {
    label: "(GMT+10:00) Vladivostok",
    value: "Asia/Vladivostok",
  },
  {
    label: "(GMT+10:00) Guam, Port Moresby",
    value: "Pacific/Guam",
  },
  {
    label: "(GMT+10:00) Brisbane",
    value: "Australia/Brisbane",
  },
  {
    label: "(GMT+10:00) Canberra, Melbourne, Sydney",
    value: "Australia/Sydney",
  },
  {
    label: "(GMT+10:00) Hobart",
    value: "Australia/Hobart",
  },
  {
    label: "(GMT+11:00)",
    value: "Etc/GMT+11",
  },
  {
    label: "(GMT+11:00) Magadan, Solomon Islands, New Caledonia",
    value: "Pacific/Guadalcanal",
  },
  {
    label: "(GMT+12:00)",
    value: "Etc/GMT+12",
  },
  {
    label: "(GMT+12:00) Auckland, Wellington",
    value: "Pacific/Auckland",
  },
  {
    label: "(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands",
    value: "Pacific/Fiji",
  },
  {
    label: "(GMT+13:00) Nuku'alofa",
    value: "Pacific/Tongatapu",
  },
  {
    label: "(GMT-01:00)",
    value: "Etc/GMT-1",
  },
  {
    label: "(GMT-01:00) Azores",
    value: "Atlantic/Azores",
  },
  {
    label: "(GMT-01:00) Cape Verde Islands",
    value: "Atlantic/Cape_Verde",
  },
  {
    label: "(GMT-02:00)",
    value: "Etc/GMT-2",
  },
  {
    label: "(GMT-02:00) Mid-Atlantic",
    value: "Atlantic/South_Georgia",
  },
  {
    label: "(GMT-03:00)",
    value: "Etc/GMT-3",
  },
  {
    label: "(GMT-03:00) Greenland",
    value: "America/Nuuk",
  },
  {
    label: "(GMT-03:00) Rio de Janeiro, Sao Paulo",
    value: "America/Sao_Paulo",
  },
  {
    label: "(GMT-03:00) Buenos Aires",
    value: "America/Buenos_Aires",
  },
  {
    label: "(GMT-03:30) Newfoundland and Labrador",
    value: "America/St_Johns",
  },
  {
    label: "(GMT-04:00)",
    value: "Etc/GMT-4",
  },
  {
    label: "(GMT-04:00) Atlantic Time (Canada)",
    value: "America/Halifax",
  },
  {
    label: "(GMT-04:00) Puerto Rico",
    value: "America/Puerto_Rico",
  },
  {
    label: "(GMT-04:00) Caracas, La Paz",
    value: "America/La_Paz",
  },
  {
    label: "(GMT-04:00) Santiago",
    value: "America/Santiago",
  },
  {
    label: "(GMT-05:00)",
    value: "Etc/GMT-5",
  },
  {
    label: "(GMT-05:00) Eastern Time (US and Canada)",
    value: "America/New_York",
  },
  {
    label: "(GMT-05:00) Panama",
    value: "America/Panama",
  },
  {
    label: "(GMT-05:00) Lima",
    value: "America/Lima",
  },
  {
    label: "(GMT-06:00)",
    value: "Etc/GMT-6",
  },
  {
    label: "(GMT-06:00) Central Time (US and Canada)",
    value: "America/Chicago",
  },
  {
    label: "(GMT-06:00) Saskatchewan",
    value: "Canada/Saskatchewan",
  },
  {
    label: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
    value: "America/Mexico_City",
  },
  {
    label: "(GMT-06:00) Costa Rica, El Salvador, Guatemala, Honduras",
    value: "America/Costa_Rica",
  },
  {
    label: "(GMT-07:00)",
    value: "Etc/GMT-7",
  },
  {
    label: "(GMT-07:00) Mountain Time (US and Canada)",
    value: "America/Denver",
  },
  {
    label: "(GMT-07:00) Arizona",
    value: "America/Phoenix",
  },
  {
    label: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
    value: "America/Mazatlan",
  },
  {
    label: "(GMT-08:00)",
    value: "Etc/GMT-8",
  },
  {
    label: "(GMT-08:00) Pacific Time (US and Canada), Tijuana",
    value: "America/Los_Angeles",
  },
  {
    label: "(GMT-09:00)",
    value: "Etc/GMT-9",
  },
  {
    label: "(GMT-09:00) Alaska",
    value: "America/Anchorage",
  },
  {
    label: "(GMT-10:00)",
    value: "Etc/GMT-10",
  },
  {
    label: "(GMT-10:00) Aleutian Islands",
    value: "America/Adak",
  },
  {
    label: "(GMT-10:00) Hawaii",
    value: "Pacific/Honolulu",
  },
  {
    label: "(GMT-11:00)",
    value: "Etc/GMT-11",
  },
  {
    label: "(GMT-11:00) Midway Island, Samoa",
    value: "Pacific/Samoa",
  },
  {
    label: "(GMT-12:00)",
    value: "Etc/GMT-12",
  },
];
