export const oktaLoginRequestPayload = (formValues) => {
  return {
    username: `${
      formValues.email === "" ? localStorage.getItem("email") : formValues.email
    }`,
    password: `${formValues.password}`,
  };
};

export const createUserPayload = (formValues) => {
  return {
    "profileCredentials": {
      "newPassword": `${formValues.password}`,
      "confirmPassword": `${formValues.confirmPassword}`
    },
    "coreProfile": {
      "identity": {
        "userId": `${formValues.emailAddress}`,
        "emailAddress": `${formValues.emailAddress}`
      },
      "firstName": `${formValues.firstName}`,
      "lastName": `${formValues.lastName}`,
      "residentCountryCode": `${formValues.countryCode}`,
    }
  }
}
export const loginUpdatePayload = (email, data)=>{
  return {
    "email": `${email}`,
    "password": `${data.password}`,
    "sessionToken": `${data.sessionToken}`
  }
}

export const oktaChangePasswordPayload = (formValues, stateToken) => {
  return {
    stateToken,
    oldPassword: `${formValues.currentPassword}`,
    newPassword: `${formValues.newPassword}`,
  };
};

export const oktaForgotPasswordPayload = (username, relayState = "") => {
  return {
    factorType: "EMAIL",
    username,
    relayState,
  };
};

export const registrationPayload = (formData) => {
  const payload = {
    userProfile: {
      hpeCountryCode: formData.selectCountry
        ? formData.selectCountry.toUpperCase()
        : formData.selectCountry,
      email: formData.email,
      password: formData.password,
      firstName: formData.firstName,
      lastName: formData.lastName,
      hpeCompanyName: formData.businessName,
      streetAddress: formData.streetAddress,
      hpeStreetAddress2: formData.streetAddress2,
      city: formData.city,
      primaryPhone: formData.phoneNumber,
      state: formData.stateOrProvince,
      zipCode: formData.postalCode,
      temporaryPassword: formData.password,
      hpeContactPreferenceEmail: formData.emailContactPreference,
      hpeContactPreferencePhone: formData.phoneContactPreference,
      hpeTimezone: formData.selectTimezone,
      preferredLanguage: formData.selectLanguage,
    },
  };

  return payload;
};
