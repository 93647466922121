import React from 'react';
import { Anchor } from 'grommet';

const AnchorGeneric = (props) => {
    const { label, href, target, rel, color, style={} } = props;
    return (
        <Anchor
            label={label}
            href={href}
            target={target || '_blank'}
            rel={rel || 'noreferrer noopener'}
            color={color || 'rgba(23,235,160,1)'}
            style={style}
        />
    );
};

export default AnchorGeneric;
