import React, { useContext } from "react";
import { Box, Header, Text } from "grommet";
import { AuthContext } from "../../AuthContext";
import { formatAnchorTag } from "../../Helpers/common";
import { gt_contact_HPE_support } from "../../Helpers/urls";

const GtErrorPage = (props) => {
  const { state } = useContext(AuthContext);
  const { statusCode } = props;

  const {
    contact_hpe_support_label,
    block_page_success_message_header,
    block_page_success_message_text,
    block_page_blocked_message_header,
    block_page_blocked_message_text,
    block_page_error_message_header,
    block_page_error_message_text,
  } = state.content;

  const gtHeader = (statusCode) => {
    let gtMessageHeader = block_page_error_message_header;
    if (statusCode === 200) gtMessageHeader = block_page_success_message_header;
    if (statusCode === 403) gtMessageHeader = block_page_blocked_message_header;
    return (
      <Text size="xlarge" weight="bold">
        {gtMessageHeader}
      </Text>
    );
  };

  const gtSubHeader = (statusCode) => {
    let gtMessageText = block_page_error_message_text;
    if (statusCode === 200) gtMessageText = block_page_success_message_text;
    if (statusCode === 403) gtMessageText = block_page_blocked_message_text;
    return gtMessageText;
  };

  return (
    <Box flex gap="medium" width={"large"} className="form-container" alignContent="center">
      <Header direction="column" className="form-header" style={{ textAlign: "center" }}>
        {gtHeader(statusCode)}
      </Header>
      <Box margin="20px 0px" align="center" style={{ textAlign: "center" }}>
        {formatAnchorTag(
          gtSubHeader(statusCode),
          contact_hpe_support_label,
          gt_contact_HPE_support
        )}
      </Box>
    </Box>
  );
};

export default GtErrorPage;
