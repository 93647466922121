import React, { useContext } from 'react';
import { Grommet, Box, Button, Header, ResponsiveContext, Text } from 'grommet';
import { hpe } from 'grommet-theme-hpe';
import { FormNext } from 'grommet-icons';
import { useHistory } from 'react-router';
import { content } from '../../Helpers/content';
import { AuthContext } from '../../AuthContext';
import Title from "../header/Title";

const EmailUpdateSuccess = () => {
  const size = useContext(ResponsiveContext);
  const { state } = useContext(AuthContext)
  const history = useHistory()
  const onLoginClick = async()=>{
    if (localStorage.getItem('fromURI')) {
      history.push({
        pathname: '/',
        search: '?fromURI=' + encodeURIComponent(localStorage.getItem('fromURI'))
      });
    } else {
      history.push('/')
    }
  }
  const {successful_email_update_title, successful_email_update_text, back_to_login_btn, login_title} = state.content;

  return (
    <Grommet theme={hpe}>
      <Box
        flex
        gap="medium"
        width="medium"
        className="form-container"
        alignContent='center'
      >
        <Title info={{ title: `HPE ${login_title}` }} />
        <Header
          direction="column"
          align="start"
          gap="xxsmall"
          pad={{ horizontal: 'xxsmall' }}
          className="form-header"
        >
          <Text size="large" weight="bold">
            {successful_email_update_title}
          </Text>
        </Header>
        
        <Text size="small" weight="normal">
          {successful_email_update_text}
        </Text>
        
        <Box
          align={'start'}
          margin={{ top: 'medium', bottom: 'small' }}
        >        
          <Button
            label={back_to_login_btn}
            reverse
            primary
            onClick ={()=>{onLoginClick()}}
            className="submit-btn"
          />
        </Box>
      </Box>
    </Grommet>
  );
};

export default EmailUpdateSuccess