import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Spinner, Box, Grommet, Text } from "grommet"
import { hpe } from "grommet-theme-hpe"
import Login from './components/login/Login'
import LoginUpdate from './components/login-update/LoginUpdate'
import Logout from './components/logout/Logout'
import ForgotPassword from './components/forgot-password/ForgotPassword.js'
import ChangedPassword from './components/change-password/ChangePassword'
import ResetPassword from './components/reset-password/ResetPassword'
import EmailVerification from './components/email-verification/EmailVerification'
import EmailVerificationSuccess from './components/email-verification-success/EmailVerificationSuccess'
import UpdateEmail from './components/update-email/UpdateEmail'
import EmailUpdateSuccess from './components/email-update-success/EmailUpdateSuccess'
import SendEmail from './components/send-email/SendEmail'
import { AuthContext } from './AuthContext'
import PageNotFound from './components/PageNotFound'
import './styles/global.scss'
import SuccessComponent from './components/success-component/SuccessComponent'
import LoginDelayed from './components/login-delayed/LoginDelayed'
import ForgotPasswordRedirect from './components/forgot-password-redirect/ForgotPasswordRedirect'
import Username from './components/username/Username'
import LogoutRedirect from './components/logout/Logout-redirect'
import { ACTIONS } from "./AuthReducer";
import Signup from "./components/signup/Signup";
import {
  getTranslations,
  insertTranslatedHeaderFooter,
  getLangCookieDetails,
  getSupportedLanguagesJson,
  getEmbargoCountryList,
  insertDynamicStyle,
  openHelpPage,
  getJSONDataFromMessagesBucket,
  dynamicHelpPage,
  openHelpIcon,
  ieCompatibalityCheck,
} from "./Helpers/common";
import BlockPage from './components/block-page/BlockPage'
import SignupComplete from './components/signup-complete/SignupComplete'
import { enviroment, deepLinkApplicationsListUrl, helpURL_dev_itg, helpURL_prod, header_helpIcon_dissable} from "./Helpers/urls";
import { constants } from "./Helpers/content";
import SessionExpired from './components/session-expired/SessionExpired'
import OOTBSiteDownPage from './components/ootb-site-down-page/OOTBSiteDownPage'
import ActivateRedirect from './components/signup-activation-redirect/ActivateRedirect'

const App = () => {
  const { state, dispatch } = useContext(AuthContext)
  const [showSpinner, setShowSpinner] = useState(false);
  const[isIEcheck, getIEversion] = ieCompatibalityCheck();

  const {
    backToSigninFlag,
    selectedLanguage,
    supportedLanguagesListDetails,
    supportedHeaderFooterList,
    deepLinkApplicationsList,
    isIE,
    isBrowserDefaultLang,
  } = state;

  useEffect(() => {
    if (enviroment === 'dev') document.title = constants.app_title_dev;
    if (enviroment === 'itg') document.title = constants.app_title_itg;
  }, []);

  useEffect(() => appInitialization(), []);

  useEffect(() => {
    if(isIEcheck){
      dispatch({
        type: ACTIONS.browserIEcheck,
        isIE: true,
      });
      const headerScriptTag = document.createElement('script');
      headerScriptTag.src = header_helpIcon_dissable(isIEcheck)
      document.head.appendChild(headerScriptTag);
    }
    if (supportedLanguagesListDetails?.length) {
      getHelpPageUrl(
        enviroment,
        supportedLanguagesListDetails,
        selectedLanguage
      );
    }
  }, [supportedLanguagesListDetails]);

  useEffect(() => {
    if (backToSigninFlag) {
      updateTranslation(
        selectedLanguage,
        supportedLanguagesListDetails,
        supportedHeaderFooterList,
        true,
        isBrowserDefaultLang
      );
      getHelpPageUrl(
        enviroment,
        supportedLanguagesListDetails,
        selectedLanguage
      );
    }
  }, [backToSigninFlag]);

  const getHelpPageUrl = (
    enviroment,
    supportedLanguagesListDetails,
    selectedLanguage
  ) => {
    const dynamicHelpPageUrl = dynamicHelpPage(
      enviroment,
      supportedLanguagesListDetails,
      selectedLanguage
    );
    openHelpPage(dynamicHelpPageUrl);
    openHelpIcon(dynamicHelpPageUrl);
  };

  const appInitialization = async () => {
    openHelpPage(enviroment === "prod" ? helpURL_prod : helpURL_dev_itg);
    insertDynamicStyle();
    try {
      await getCookie();
      await getEmbargoCountryDetails();
      await getDeepLinkApplicationsDetails();
      onAppMount();
    } catch (err) {
      onAppMount();
      return err;
    }
  };

  const onAppMount = () =>
    dispatch({
      type: ACTIONS.updateAppMount,
      data: true,
    });

    const updateTranslation = async (
      lang,
      list = [],
      supportedHeaderFooterList = [],
      shouldUpdateSelectedLang,
      isBrowserDefaultLang
    ) => {
      if (!lang) lang = "en";
      try {
        await getLanguageContent(lang, shouldUpdateSelectedLang, isBrowserDefaultLang);
        if (!shouldUpdateSelectedLang && lang === "en") return;
        insertTranslatedHeaderFooter(lang, list, supportedHeaderFooterList);
      } catch (err) {
        return err;
      }
    };

  const getCookie = async () => {
    try {
      setShowSpinner(true);
      let list = [];
      const res = await getSupportedLanguageDetails();
      if (res?.supportedLanguagesList) list = res.supportedLanguagesList;
      const { lang, shouldUpdateSelectedLang, isBrowserDefaultLang } = getLangCookieDetails(false, list);
      setShowSpinner(false);
      await updateTranslation(
        lang,
        res?.supportedLanguagesListDetails,
        res?.supportedHeaderFooterList,
        shouldUpdateSelectedLang,
        isBrowserDefaultLang
      );
    } catch (err) {
      setShowSpinner(false);
      console.log(err);
    }
  };

  const getLanguageContent = async (language, shouldUpdateSelectedLang, isBrowserDefaultLang) => {
    setShowSpinner(true);
    try {
      const { data, lang, _lang } = await getTranslations(language);
      setShowSpinner(false);
      if (data?.details?.scope) {
        dispatch({
          type: ACTIONS.changeLanguage,
          data: data.details.scope,
          lang: shouldUpdateSelectedLang ? (lang ? lang : _lang) : "",
          languageCode: data.details?.languageCode,
          isBrowserDefaultLang: isBrowserDefaultLang ? true : false,
        });
      }
    } catch (err) {
      setShowSpinner(false);
      console.log(err);
    }
  };

  const getSupportedLanguageDetails = async () => {
    try {
      const list = await getSupportedLanguagesJson();
      if (!list) return;
      dispatch({
        type: ACTIONS.getSupportedlanguageDetails,
        data: list,
      });
      return list;
    } catch (err) {
      return err;
    }
  };

  const getEmbargoCountryDetails = async () => {
    try {
      const list = await getEmbargoCountryList();
      if (!list) return;
      dispatch({
        type: ACTIONS.getEmbargoCountryDetails,
        data: list,
      });
    } catch (err) {
      return err;
    }
  };

  const getDeepLinkApplicationsDetails = async () => {
    try {
      const list = await getJSONDataFromMessagesBucket(
        deepLinkApplicationsListUrl
      );
      if (!list) return;
      dispatch({
        type: ACTIONS.updateDeepLinkApplicationsList,
        data: list,
      });
    } catch (err) {
      return err;
    }
  };

  if (showSpinner) {
    return (
      <div className="main-section">
        <Grommet theme={hpe}>
          <Box align="center" justify="center" height={{ min: "small" }}>
            <Spinner size="medium" />
          </Box>
        </Grommet>
      </div>
    );
  }
  
  const ieInCompatibilityErrorMessage = () => {
    return (
      <Box
        align="center"
        justify="center"
        pad="large"
        margin={{ bottom: "-50px", top: "-20px" }}
        height={{ min: "small" }}
        style={{ fontFamily: "'Metric',Arial,sans-serif", color: "#444444" }}
      >
        <Text size="large" weight="normal">
          {constants.ie_browser_info}
        </Text>
        <Text size="large" weight="normal">
          {constants.ie_browser_info_2}
        </Text>
      </Box>
    );
  };


  return (
    <div>
      <div className="main-section">
        {isIE && ieInCompatibilityErrorMessage()}
        <Router basename="/hpe/cf">
          <Switch>
          {state.loginDelayAuth && <Route exact path="/login-delayed">
              <LoginDelayed/>
              </Route>}
              {state.forgotPwdRedirectAuth && <Route exact path="/forgot-password-redirect">
              <ForgotPasswordRedirect/>
              </Route>}
            {state.successPasswordAuth && <Route exact path="/success">
              <SuccessComponent />
              </Route>}
            <Route exact path="/forgot-password">
              <ForgotPassword />
            </Route>
            {state.changePasswordAuth && (<Route exact path="/change-password">
              <ChangedPassword />
            </Route>)}
            <Route exact path="/reset-password">
              <ResetPassword />
            </Route>
            {state.sendEmailAuth && <Route exact path='/send-email'><SendEmail /></Route>}
            {state.emailVerificationSuccessAuth && <Route exact path="/email-verification-success">
              <EmailVerificationSuccess />
            </Route>}
            {state.emailUpdateSuccessAuth && <Route exact path="/email-update-success">
              <EmailUpdateSuccess />
            </Route>}
            {state.updateEmailAuth && <Route exact path="/update-email">
              <UpdateEmail />
            </Route>}
            {state.verifyEmailAuth && <Route exact path="/verify-email">
              <EmailVerification />
            </Route>}
            <Route exact path='/login-update'>
              <LoginUpdate />
            </Route>
            {state.loginAuth && <Route path="/login">
              <Login />
            </Route>}
            {state.loginAuth && <Route exact path="/">
              <Username/>
            </Route>}
            {state.logoutAuth && <Route exact path="/logout">
              <Logout />
            </Route>}
            <Route exact path="/registration">
              <Signup />
            </Route>
            <Route exact path="/registration-complete">
              <SignupComplete />
            </Route>
            <Route exact path="/session-expired">
              <SessionExpired />
            </Route>
            <Route exact path="/complete-site-down">
              <OOTBSiteDownPage />
            </Route>
            <Route exact path="/activate-redirect">
              <ActivateRedirect />
            </Route>
            {/* <Route exact path="/sessionRedirect">
              <SessionCookieRedirect/>
            </Route> */}
            <Route exact path="/logout-redirect">
              <LogoutRedirect/>
            </Route>
            <Route path="/blockpage">
              <BlockPage />
            </Route>
            <Route path='*'><PageNotFound /></Route>
          </Switch>
        </Router>
      </div>

    </div>
  )
}
export default App
