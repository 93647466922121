export const targetEndpoint = process.env.REACT_APP_TARGET_ENDPOINT;
export const applicationURL = process.env.REACT_APP_APPLICATION_URL;
export const session_endpoint = process.env.REACT_APP_SESSION_ENDPOINT;
export const registration_endpoint = process.env.REACT_APP_REGISTRATION_ENDPOINT;
export const login_update_endpoint = `${targetEndpoint}/v2-login-update`
export const gts_validate = `${targetEndpoint}/v2-sid-validate-gt-flow`
export const okta_login_endpoint = `${applicationURL}/api/v1/authn`
export const okta_change_password_endpoint = `${okta_login_endpoint}/credentials/change_password`
export const okta_forgot_password_endpoint = `${okta_login_endpoint}/recovery/password`
export const okta_current_user_info_endpoint = `${applicationURL}/api/v1/users/me`
