import React, { useContext } from 'react';
import {
  Grommet,
  Box,
  Button,
  Header,
  ResponsiveContext,
  Text
} from 'grommet';
import { hpe } from 'grommet-theme-hpe';
import { FormNext } from 'grommet-icons';
import { useHistory } from 'react-router';
import { AuthContext } from '../../AuthContext';
import { appendFromURI } from "../../Helpers/common";

const LoginDelayed = () => {
  const size = useContext(ResponsiveContext);
  const { state } = useContext(AuthContext)
  const history = useHistory()
  const {login_delayed_title, login_delayed_text, back_to_login_btn} = state.content
  
  const onLoginClick = async()=>{
    history.push(appendFromURI());
  }

  return (
    <Grommet theme={hpe}>
      <Box
        flex
        gap="medium"
        width="medium"
        className="form-container"
        alignContent='center'
      >
        <Header
          direction="column"
          align="start"
          gap="xxsmall"
          pad={{ horizontal: 'xxsmall' }}
          className="form-header"
        >
          <Text size="xxlarge" weight="bold">
            {login_delayed_title}
          </Text>
        </Header>
        
        <Text size="small" weight="normal">
          {login_delayed_text}
        </Text>
        
        <Box
          align={size !== 'small' ? 'end' : undefined}
          margin={{ top: 'medium', bottom: 'small' }}
        >        
          <Button
            label={back_to_login_btn}
            icon={<FormNext />}
            reverse
            primary
            onClick ={()=>{onLoginClick()}}
            className="submit-btn"
          />
        </Box>
      </Box>
    </Grommet>
  );
};

export default LoginDelayed