import React, { useContext } from "react";
import { Grommet, Box, Button, ResponsiveContext, Text } from "grommet";
import { Checkmark } from "grommet-icons";
import { hpe } from "grommet-theme-hpe";
import { useHistory } from "react-router";
import { AuthContext } from "../../AuthContext";
import { backToSignInUsingRelayState } from "../../Helpers/common";

const SignupComplete = () => {
  const size = useContext(ResponsiveContext);
  const { state } = useContext(AuthContext);
  const history = useHistory();
  const {
    signup_complete_verification_email,
    signup_complete_check_email,
    back_to_sign_in,
  } = state.signUp;

  let getRelayState = sessionStorage.getItem("RelayState");
  const backToSignInHandler = () => {
    if (history.length <= 1) {
      backToSignInUsingRelayState(getRelayState, history);
    } else history.goBack();
  };

  return (
    <Grommet theme={hpe}>
      <Box
        flex
        gap="medium"
        width="medium"
        className="form-container"
        alignContent="center"
      >
        <Box align="center">
          <Checkmark
            color="white"
            style={{
              background: "#46b090",
              height: "15px",
              width: "15px",
              borderRadius: "50%",
              padding: "8px",
              weight: "300",
            }}
          />
          <Text size="medium" weight="bold" color="#46b090">
            {" "}
            {signup_complete_verification_email}
          </Text>
        </Box>

        <Box align="center">
          <Text size="small" weight="normal" color="#777">
            {" "}
            {signup_complete_check_email}{" "}
          </Text>
        </Box>

        <Box
          align={size !== "small" ? "start" : undefined}
          margin={{ top: "medium", bottom: "xxxlarge", left: "30px" }}
        >
          <Button onClick={() => backToSignInHandler()} size="xxxsmall">
            <u>{back_to_sign_in}</u>
          </Button>
        </Box>
      </Box>
    </Grommet>
  );
};

export default SignupComplete;
