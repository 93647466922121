import React, { useContext, useState } from 'react';
import {
  Grommet,
  Box,
  Button,
  Form,
  FormField,
  Header,
  ResponsiveContext,
  Text,
  TextInput
} from 'grommet';
import { FormNext } from 'grommet-icons';
import { hpe } from 'grommet-theme-hpe';
import { fetchData } from '../../Data/fetchData'
import { loginUpdatePayload } from '../../Helpers/requestsPayload';
import { useLocation, useHistory } from 'react-router';
import { content } from '../../Helpers/content';
import { AuthContext } from '../../AuthContext';

const LoginUpdate = () => {
  const size = useContext(ResponsiveContext);
  const { state } = useContext(AuthContext)
  const location = useLocation()
  const history = useHistory()
  const [formValues, setFormValues] = useState({
    email: '',
  });
  const {login_update_title,company_subtext, email_label, email_ph, login_update_btn, required_field} = state.content
  const onSubmit = ({ value, touched }) => {
    loginUpdate()
  }
  const onChange = (values) => {
    setFormValues(values)
  }
  const loginUpdate = async () => {
    let data = await fetchData('/v1-login-update', loginUpdatePayload(formValues, location.state))

    if (data.id) {
      history.push('/dashboard')
    }
    else {
      console.log('Login Update Failed')
    }
  }
  return (
    <Grommet theme={hpe}>
      <Box
        flex
        gap="medium"
        width="medium"
        className="form-container"
        alignContent='center'
      >
        <Header
          direction="column"
          align="start"
          gap="xxsmall"
          pad={{ horizontal: 'xxsmall' }}
          className="form-header"
        >
          <Text size="xxlarge" weight="bold">
            {login_update_title}
          </Text>
          <Text>{company_subtext}</Text>
        </Header>
        <Box
          // Padding used to prevent focus from being cutoff
          pad={{ horizontal: 'xxsmall' }}
        >
          <Form
            validate="blur"
            value={formValues}
            onChange={(value) => onChange(value)}
            messages={{
              required: required_field,
            }}
            onSubmit={({ value, touched }) => onSubmit({ value, touched })}
          >

            <FormField
              required
              label={email_label}
              name="email"
              htmlFor="email-sign-in"
            >
              <TextInput
                id="email-sign-in"
                name="email"
                type='email'
                placeholder={email_ph}
              />
            </FormField>
            <Box
              align={size !== 'small' ? 'end' : undefined}
              margin={{ top: 'medium', bottom: 'small' }}
            >
              <Button
                label={login_update_btn}
                icon={<FormNext />}
                reverse
                primary
                type="submit"
                className="submit-btn"
              />
            </Box>
          </Form>
        </Box>
      </Box>
    </Grommet>
  )
}

export default LoginUpdate
