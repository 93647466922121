import React, { useState, useContext, useEffect } from "react";
import {
  Grommet,
  Box,
  Button,
  CheckBox,
  Form,
  FormField,
  Header,
  Text,
  TextInput,
  Anchor,
  Spinner,
  Select,
  RadioButtonGroup,
  MaskedInput,
  Tip,
} from "grommet";
import { CircleAlert, CircleInformation } from "grommet-icons";
import { useHistory, useLocation } from "react-router-dom";
import { hpe } from "grommet-theme-hpe";
import { AuthContext } from "../../AuthContext";
import {
  registrationEmailMask,
  registrationNameMask,
} from "../../Helpers/Validations";
import { registrationPayload } from "../../Helpers/requestsPayload";
import {
  hpeTermsOfUseUrl,
  hpePrivacyNoticeUrl,
  okta_user_lang_cookie_domain,
  signupActivateRedirectUrl,
  enviroment,
} from "../../Helpers/urls";
import { registration_endpoint } from "../../Helpers/endpoints";
import { fetchData } from "../../Data/fetchData";
import {
  getTranslations,
  restrictedCountrycheck,
  setRegistrationCookie,
  getLanguageAndCountryCode,
  insertTranslatedHeaderFooter,
  isLocalDev,
  oktaCookieUpdate,
  getLangCookieDetails,
  filterLanguageAndCountryCode,
  activateRedirectCookieUpdate,
  formatLang,
  openHelpPage,
  dynamicHelpPage,
  replaceString,
  getChinaPolicyLink,
  backToSignInUsingRelayState,
  setSessionStorage,
} from "../../Helpers/common";
import { ACTIONS } from "../../AuthReducer";
import Cookies from "js-cookie";

const Signup = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [showSpinner, setShowSpinner] = useState(false);
  const [formError, setFormError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [currentStyle, setCurrentStyle] = useState("en");
  const history = useHistory();
  const location = useLocation();
  const search = location.search;
  let relayState = new URLSearchParams(search).get("RelayState");
  let appName = new URLSearchParams(search).get("appname");

  if (!relayState) {
    relayState = new URLSearchParams(search).get("fromURI");
  }

  if (relayState && history.length <= 1)
    setSessionStorage("RelayState", relayState);

  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    businessName: "",
    streetAddress: "",
    streetAddress2: "",
    city: "",
    stateOrProvince: "",
    postalCode: "",
    phoneNumber: "",
    countryCode: "",
    extension: "",
    selectLanguage: "",
    selectCountry: "",
    selectTimezone: "",
    phoneContactPreference: "",
    emailContactPreference: "",
  });

  const {
    content,
    signUp,
    HPE_privacy_notice,
    password_validation,
    signupValidation,
    registration_Password_Rules,
    countryList=[],
    timezoneList=[],
    selectedLanguage,
    languageCode,
    supportedLanguagesList=[],
    supportedLanguagesListDetails=[],
    embargoCountryList=[],
    supportedHeaderFooterList=[],
    deepLinkApplicationsList=[],
    isBrowserDefaultLang,
  } = state;

  const {
    email_id,
    password_label,
    valid_email_inline_error,
    back_to_sign_in,
    required_field,
    system_error,
  } = content;

  const {
    first_name,
    last_name,
    street_address,
    street_address2,
    city,
    postal_code,
    country,
    create_an_hpe_account,
    state_or_province,
    language,
    registration_subheader,
    organization_information,
    organization_name,
    time_zone,
    contact_preference,
    personalised_communications,
    phone,
    yes,
    no,
    create_account,
    select,
    required_field_1,
    restricted_country,
    language_coming_soon,
    select_one_placeholder,
    select_language,
    select_country,
    select_timezone,
  } = signUp;

  const {
    privacy_policy_accept,
    hpe_terms_of_use,
    privacy_policy_china,
    privacy_policy_other_countries,
    hpe_privacy_notice,
    china_policy_terms_1,
    china_policy_terms_2,
    china_policy_terms_3,
  } = HPE_privacy_notice;

  const {
    password_validation_requirement,
    password_upper_case,
    password_lower_case,
    password_one_number,
    password_one_symbol,
    password_minimum_length,
    password_not_username,
    password_not_firstname,
    password_not_lastname,
    password_not_common_pwd,
    password_not_match_24pwd,
    password_last_change_one_hr,
  } = password_validation;

  const {
    fn_validation,
    ln_validation,
    validation_51charcters,
    general_validation,
    blank_validation,
    email_domain_validation,
    validation_first_name_23_charcters,
    validation_last_name_23_charcters,
    registration_phone_number_label,
    validation_phoneNumber,
    errorcode_email_already_exists,
    password_requirement_failed,
    errorcode_not_found,
    errorcode_registration_incomplete,
  } = signupValidation;

  const {
    registration_Password_Rule_one_uppercase,
    registration_Password_Rules_one_lowercase,
    registration_Password_Rules_one_special,
    registration_Password_Rules_one_number,
    registration_Password_Rules_14_char,
    registration_Password_Rules_8_char,
  } = registration_Password_Rules;

  const emailValidation = [
    {
      regexp: new RegExp("[^@ \\t\\r\\n]+@"),
      message: valid_email_inline_error,
      status: "error",
    },
    {
      regexp: new RegExp("[^@ \\t\\r\\n]+@[^@ \\t\\r\\n]+\\.[^@ \\t\\r\\n]+"),
      message: valid_email_inline_error,
      status: "error",
    },
    {
      regexp: new RegExp("[^@ \\t\\r\\n]+@[^@ \\t\\r\\n]+\\.[^@ \\t\\r\\n]+"),
      message: valid_email_inline_error,
      status: "error",
    },
    () => {
      if (embargoCheck(formValues.email))
        return {
          message: email_domain_validation,
          status: "error",
        };
    },
  ];

  const registrationPasswordRulesStrong = [
    {
      regexp: new RegExp("(?=.*?[A-Z])"),
      message: registration_Password_Rule_one_uppercase,
      status: "error",
    },
    {
      regexp: new RegExp("(?=.*?[a-z])"),
      message: registration_Password_Rules_one_lowercase,
      status: "error",
    },
    {
      regexp: new RegExp("(?=.*?[#?!@$ %^&*-])"),
      message: registration_Password_Rules_one_special,
      status: "error",
    },
    {
      regexp: new RegExp("(?=.*?[0-9])"),
      message: registration_Password_Rules_one_number,
      status: "error",
    },
    {
      regexp: new RegExp(".{8,}"),
      message: registration_Password_Rules_8_char,
      status: "error",
    },
  ];

  const firstNameValidation = [
    {
      regexp: /^[^_?^/@&%§$£æ"¿œ"�<>#|_!()*+0123456789]+$/,
      message: fn_validation,
      status: "error",
    },
    {
      regexp: new RegExp('^[^_?^/@&%§$£æ"¿œ"�<>#|_!()*+0123456789]{0,50}$'),
      message: validation_51charcters,
      status: "error",
    },
  ];

  const lastNameValidation = [
    {
      regexp: /^[^_?^/@&%§$£æ"¿œ"�<>#|_!()*+0123456789]+$/,
      message: ln_validation,
      status: "error",
    },
    {
      regexp: new RegExp('^[^_?^/@&%§$£æ"¿œ"�<>#|_!()*+0123456789]{0,50}$'),
      message: validation_51charcters,
      status: "error",
    },
  ];

  const exceptionalCountryValidationFirstName = [
    {
      regexp: /^[^_?^/@&%§$£æ"¿œ"�<>#|_!()*+0123456789]+$/,
      message: fn_validation,
      status: "error",
    },
    {
      regexp: new RegExp('^[^_?^/@&%§$£æ"¿œ"�<>#|_!()*+0123456789]{0,23}$'),
      message: validation_first_name_23_charcters,
      status: "error",
    },
  ];

  const exceptionalCountryValidationLastName = [
    {
      regexp: /^[^_?^/@&%§$£æ"¿œ"�<>#|_!()*+0123456789]+$/,
      message: ln_validation,
      status: "error",
    },
    {
      regexp: new RegExp('^[^_?^/@&%§$£æ"¿œ"�<>#|_!()*+0123456789]{0,23}$'),
      message: validation_last_name_23_charcters,
      status: "error",
    },
  ];

  const genericValidation = [
    {
      regexp: /^[^<>]+$/,
      message: general_validation,
      status: "error",
    },
  ];

  const phoneNumberValidation = [
    {
      regexp: /^[0-9 \-+]+$/,
      message: validation_phoneNumber,
      status: "error",
    },
  ];

  const testValidation = [
    {
      regexp: /^[^<>]+$/,
      message: required_field,
      status: "error",
    },
  ];

  useEffect(() => {
    setFormValues({ ...formValues, selectLanguage: isBrowserDefaultLang ? "" : selectedLanguage });
    setCurrentStyle(languageCode);
  }, [state.selectedLanguage]);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "POP") backToSignin();
    });
  }, []);


  const getLanguageContent = async (language, shouldUpdateSelectedLang, updateBrowserDefaultLang) => {
    try {
      const { data, lang, _lang } = await getTranslations(language);
      if (data?.details?.scope) {
        let updatedLang = lang;
        if (_lang) updatedLang = _lang;
        updateLanguage(data, updatedLang, shouldUpdateSelectedLang, updateBrowserDefaultLang);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateLanguage = (data, lang, shouldUpdateSelectedLang, updateBrowserDefaultLang) => {
    dispatch({
      type: ACTIONS.changeLanguage,
      data: data.details.scope,
      lang: !shouldUpdateSelectedLang ? lang : selectedLanguage,
      languageCode: data.details?.languageCode,
      isBrowserDefaultLang: updateBrowserDefaultLang
        ? false
        : isBrowserDefaultLang
        ? true
        : false,
    });
  };

  const exceptionalCountryList = ["cn", "tw", "jp", "kr", "by", "gr", "il"];
  const chinaCountryPolicy = ["cn"];
  const restrictedCountry = ["ru"];

  const nameCheck = (countryCode) =>
    restrictedCountrycheck(exceptionalCountryList, countryCode);

  const chinaCountryCheck = (countryCode) =>
    restrictedCountrycheck(chinaCountryPolicy, countryCode);

  const embargoCountrycheck = (countryCode) =>
    restrictedCountrycheck(embargoCountryList, countryCode);

  // var restrictedCountryList = ["RU"];
  const embargoCheck = (email) => {
    let emailSecondPart = email.split("@")[1];
    if (!emailSecondPart) return false;
    let userEmailDomain = emailSecondPart.split(".");
    userEmailDomain = userEmailDomain[userEmailDomain.length - 1];
    const list = embargoCountryList;
    for (let index = 0; index < list.length; index++) {
      if (userEmailDomain.toUpperCase() === list[index].toUpperCase()) {
        return true;
      }
    }
    return false;
  };

  const onChange = (values) => {
    setFormValues(values);
  };

  const languageHandler = async (event) => {
    let lang = event.target.value;
    if (!lang) {
      try {
        const { lang: cookie } = getLangCookieDetails(
          true,
          supportedLanguagesList
        );
        await getLanguageContent(cookie, true);
        insertTranslatedHeaderFooter(
          cookie,
          supportedLanguagesListDetails,
          supportedHeaderFooterList
        );
        setFormError(false);
        setCurrentStyle(formatLang(cookie) || "en");
        const dynamicHelpIcon = dynamicHelpPage(
          enviroment,
          supportedLanguagesListDetails,
          lang
        );
        openHelpPage(dynamicHelpIcon);
        onChange({ ...formValues, selectLanguage: "" });
      } catch (err) {
        return err;
      }
    } else {
      try {
        await getLanguageContent(lang, true);
        insertTranslatedHeaderFooter(
          lang,
          supportedLanguagesListDetails,
          supportedHeaderFooterList
        );
        setFormError(false);
        setCurrentStyle(lang);
        const dynamicHelpIcon = dynamicHelpPage(
          enviroment,
          supportedLanguagesListDetails,
          lang
        );
        openHelpPage(dynamicHelpIcon);
        onChange({ ...formValues, selectLanguage: lang });
      } catch (err) {
        return err;
      }
    }
  };

  const backToSignin = async (goBackFlag) => {
    dispatch({
      type: ACTIONS.backToSigninFlag,
      data: Math.random(),
      signupLanguage: formValues.selectLanguage,
    });
    if (goBackFlag) history.goBack();
  };

  let getRelayState = sessionStorage.getItem("RelayState");
  const backToSignInHandler = () => {
    if (history.length <= 1) {
      backToSignInUsingRelayState(getRelayState, history);
    } else backToSignin(true);
  };

  const getUpdatedCountryData = () => {
    let updatedCountryList = [...countryList]
      .sort((a, b) => a.label.localeCompare(b.label, { sensitivity: "base" }))
      .map((list) => ({
        ...list,
        value: list.value.toLowerCase(),
      }));
    return [
      {
        label: <div className="select-placeholder">{`${select_country}`}</div>,
        value: "",
        cc: "",
      },
      ...updatedCountryList,
    ];
  };

  const getLanguageOptions = () => {
    return [
      {
        label: <div className="select-placeholder">{`${select_language}`}</div>,
        value: "",
        cc: "",
      },
      ...supportedLanguagesListDetails,
    ];
  };

  let deeplinkFlag = false;
  let deeplinkRelayState = '';

  const deeplinkCheck = (relayState, deepLinkApplicationsList) => {
    const data = Cookies.get("activationRedirect");
    if (!data) {
      deeplinkFlag = false;
      return;
    }

    if (data && deepLinkApplicationsList?.length) {
      const link = deepLinkApplicationsList.find((url) =>
        decodeURIComponent(data).includes(url)
      );
      if (link) {
        deeplinkFlag = true;
        // activateRedirectCookieUpdate(data, okta_user_lang_cookie_domain);
        // deeplinkRelayState = "http://localhost:3000/hpe/cf/activate-redirect";
        deeplinkRelayState = signupActivateRedirectUrl;
      } else {
        deeplinkFlag = false;
      }
    }
  };

  const gtOIDCFlowCheck = (relayState) => {
    if (!relayState) return;
    if (decodeURIComponent(relayState).includes("/v1/authorize")) {
      deeplinkFlag = true;
      activateRedirectCookieUpdate(
        replaceString(relayState, "&filter=false", ""),
        okta_user_lang_cookie_domain
      );
      // deeplinkRelayState = "http://localhost:3000/hpe/cf/activate-redirect/?oidc_app=true";
      deeplinkRelayState = `${signupActivateRedirectUrl}/?oidc_app=true`;
    } else {
      deeplinkFlag = false;
    }
  };

  const relayStateUpdate = (payload, deeplinkFlag, appName, relayState) => {
    if (deeplinkFlag) {
      if (appName) {
        if (relayState) {
          payload.relayState = deeplinkRelayState;
        }
  
        payload.userProfile.applicationContext = appName;
      }
  
      if (!appName && relayState) {
        payload.relayState = deeplinkRelayState;
        payload.userProfile.applicationContext = relayState;
      }
    } else {
      if (appName) {
        relayState
          ? (payload.relayState = relayState)
          : (payload.relayState = appName);
  
        payload.userProfile.applicationContext = appName;
      }
  
      if (!appName && relayState) {
        payload.relayState = relayState;
        payload.userProfile.applicationContext = relayState;
      }
    }
    return payload;
  }

  const onSubmit = async ({ value, touched }) => {
    setShowSpinner(true);
    if (formError) setFormError(false);
    const formData = { ...value };
    const langWithLocale = formData.selectLanguage;
    let chineseCC;
    if (langWithLocale) {
      if (langWithLocale.includes('zh')) {
        const { _lang, _cc } = getLanguageAndCountryCode(langWithLocale);
        formData.selectLanguage = _lang;
        chineseCC = _cc.toUpperCase();
      }
    }
    let payload = registrationPayload(formData);
    
    deeplinkCheck(relayState, deepLinkApplicationsList);
    gtOIDCFlowCheck(relayState);

    relayStateUpdate(payload, deeplinkFlag, appName, relayState);

    for (const item in payload.userProfile) {
      if (payload.userProfile[item] === "") delete payload.userProfile[item];
    }

    if (payload.userProfile.hpeCountryCode) {
      payload.userProfile.countryCode = payload.userProfile.hpeCountryCode;
    }

    if (payload.userProfile.preferredLanguage) {
      if (payload.userProfile.preferredLanguage.toLowerCase() === 'zh') {
        payload.userProfile.locale = `${payload.userProfile.preferredLanguage}_${chineseCC || 'CN'}`;
      } else {
        payload.userProfile.locale = `${payload.userProfile.preferredLanguage}_${payload.userProfile.hpeCountryCode}`;
      }
    } 

    if(chinaCountryCheck(formValues.selectCountry)){
      payload.userProfile.hpe_terms_conditions_china_privacy = "Yes";
    }

    try {
      const data = await fetchData(registration_endpoint, payload, false);
      setShowSpinner(false);
      if (data && data.errorCode) {
        setFormError(true);
        if (
          data.errorCode === "E0000001" &&
          data.errorCauses[0].errorSummary ===
            "A user with this Email already exists"
        ) {
          setErrMessage(errorcode_email_already_exists);
          return;
        }
        if (
          data.errorCode === "E0000001" &&
          data.errorSummary === "Api validation failed: null"
        ) {
          setErrMessage(password_requirement_failed);
          return;
        }
        if (
          data.errorCode === "E0000007" &&
          data.errorSummary === "Not found: Resource not found: me (Session)"
        ) {
          setErrMessage(errorcode_not_found);
          return;
        }
        if (
          data.errorCode === "E0000135" &&
          data.errorSummary === "Registration cannot be completed at this time"
        ) {
          setErrMessage(errorcode_registration_incomplete);
          return;
        }
        setErrMessage(system_error);
        return;
      } else {
        if (formData.selectLanguage) {
          let countryCode = chineseCC ? chineseCC : formData.selectCountry;
          setRegistrationCookie(
            formData.selectLanguage,
            countryCode,
            okta_user_lang_cookie_domain
          );
          getLanguageContent(
            `${formData.selectLanguage}_${countryCode}`,
            false,
            true
          );
        } else {
          if (touched.selectLanguage && !formData.selectLanguage) {
            Cookies.remove("hpeIamLang", {
              path: "/",
              domain: isLocalDev() ? "localhost" : ".hpe.com",
            });
            oktaCookieUpdate("", okta_user_lang_cookie_domain);
          }
        }
        history.replace({
          //TODO
          pathname: "/registration-complete",
          state: { fromURI: relayState },
        });
      }
    } catch (err) {
      setShowSpinner(false);
      setFormError(true);
      setErrMessage(system_error);
      console.log(err);
    }
  };

  return (
    <Grommet theme={hpe}>
      <Box
        flex
        gap="small"
        width="medium"
        className="form-container"
        alignContent="center"
      >
        <Header
          direction="column"
          align="start"
          gap="xxsmall"
          pad={{ horizontal: "xxsmall" }}
          className="form-header"
          margin="70px 0px 50px 0px"
        >
          <Text size="36px" weight="bold">
            {create_an_hpe_account}
          </Text>
          <Text size="large" color="#777" weight="500">
            {registration_subheader}
          </Text>
        </Header>
        <Form
          validate="blur"
          value={formValues}
          messages={{
            required: required_field,
          }}
          onChange={(nextValue) => onChange(nextValue)}
          onSubmit={({ value, touched }) => onSubmit({ value, touched })}
        >
          <Box pad={{ horizontal: "xxsmall" }}>
            <FormField
              required
              name="email"
              htmlFor="email"
              label={email_id}
              validate={emailValidation}
              // error={
              //   embargoCheck(formValues.email) ? email_domain_validation : ""
              // }
            >
              <MaskedInput
                id="email"
                name="email"
                type="email"
                mask={registrationEmailMask}
              />
            </FormField>
            <FormField
              required={false && { indicator: false }}
              name="password"
              htmlFor="password"
              label={
                <Box direction="row" align="center">
                  <Text size="xsmall">{password_label}*</Text>
                  <Tip
                    content={
                      <Box pad="small" gap="xxsmall">
                        <Text size="14px">
                          {password_validation_requirement}
                        </Text>
                        <Box style={{ fontSize: "14px", paddingLeft: "10px" }}>
                          <li>{password_upper_case}</li>{" "}
                          <li>{password_lower_case}</li>{" "}
                          <li>{password_one_number}</li>{" "}
                          <li>{password_one_symbol}</li>{" "}
                          <li>{password_not_username}</li>{" "}
                          <li>{password_not_firstname}</li>{" "}
                          <li>{password_not_lastname}</li>{" "}
                          <li>{password_not_common_pwd}</li>{" "}
                          <li>{password_not_match_24pwd}</li>{" "}
                        </Box>
                      </Box>
                    }
                    dropProps={{ align: { left: "right" } }}
                  >
                    <Button
                      a11yTitle="close"
                      size="small"
                      icon={<CircleInformation color="rgba(23,235,160,1)" />}
                    />
                  </Tip>
                </Box>
              }
              validate={
                formValues.password
                  ? registrationPasswordRulesStrong
                  : testValidation
              }
            >
              <TextInput id="password" name="password" type="password" />
            </FormField>
            <FormField
              required
              name="firstName"
              htmlFor="firstName"
              label={first_name}
              validate={
                nameCheck(formValues.selectCountry)
                  ? exceptionalCountryValidationFirstName
                  : firstNameValidation
              }
            >
              <MaskedInput
                id="firstName"
                name="firstName"
                type="firstName"
                mask={registrationNameMask}
              />
            </FormField>
            <FormField
              required
              name="lastName"
              htmlFor="lastName"
              label={last_name}
              validate={
                nameCheck(formValues.selectCountry)
                  ? exceptionalCountryValidationLastName
                  : lastNameValidation
              }
            >
              <MaskedInput
                id="lastName"
                name="lastName"
                type="lastName"
                mask={registrationNameMask}
              />
            </FormField>
            <Header
              direction="column"
              align="start"
              gap="xxsmall"
              pad={{ horizontal: "xxsmall" }}
              margin={{ top: "20px", bottom: "20px" }}
              className="form-header"
            >
              <Text size="26px" weight="bold">
                {organization_information}
              </Text>
            </Header>

            <FormField
              name="businessName"
              htmlFor="businessName"
              label={organization_name}
              validate={formValues.businessName ? genericValidation : ""}
            >
              <TextInput id="businessName" name="businessName" />
            </FormField>
            <FormField
              name="streetAddress"
              htmlFor="streetAddress"
              label={street_address}
              validate={formValues.streetAddress ? genericValidation : ""}
            >
              <TextInput id="streetAddress" name="streetAddress" />
            </FormField>
            <FormField
              name="streetAddress2"
              htmlFor="streetAddress2"
              label={street_address2}
              validate={formValues.streetAddress2 ? genericValidation : ""}
            >
              <TextInput id="streetAddress2" name="streetAddress2" />
            </FormField>
            <FormField
              name="city"
              htmlFor="city"
              label={city}
              validate={formValues.city ? genericValidation : ""}
            >
              <TextInput id="city" name="city" />
            </FormField>
            <Box direction="row" gap="medium">
              <FormField
                name="stateOrProvince"
                htmlFor="stateOrProvince"
                label={state_or_province}
                validate={formValues.stateOrProvince ? genericValidation : ""}
              >
                <TextInput id="stateOrProvince" name="stateOrProvince" />
              </FormField>
              <FormField
                name="postalCode"
                htmlFor="postalCode"
                label={postal_code}
                validate={formValues.postalCode ? genericValidation : ""}
              >
                <TextInput id="postalCode" name="postalCode" />
              </FormField>
            </Box>

            <FormField
              htmlFor="selectCountry__input"
              name="selectCountry"
              label={country}
              required={required_field}
              validate={() => {
                if (embargoCountrycheck(formValues.selectCountry))
                  return {
                    message: restricted_country,
                    status: "error",
                  };
              }}
            >
              <Select
                id="selectCountry"
                name="selectCountry"
                placeholder={`${select_country}`}
                options={getUpdatedCountryData()}
                labelKey="label"
                valueKey={{ key: "value", reduce: true }}
              />
            </FormField>
            <FormField
              htmlFor="selectLanguage__input"
              name="selectLanguage"
              label={language}
            >
              <Select
                id="selectLanguage"
                name="selectLanguage"
                placeholder={`${select_language}`}
                options={getLanguageOptions()}
                labelKey="label"
                valueKey={{ key: "value", reduce: true }}
                onChange={languageHandler}
              />
            </FormField>

            <FormField
              htmlFor="selectTimezone__input"
              name="selectTimezone"
              label={time_zone}
            >
              <Select
                id="selectTimezone"
                name="selectTimezone"
                placeholder={`${select_timezone}`}
                options={[
                  {
                    label: (
                      <div className="select-placeholder">{`${select_timezone}`}</div>
                    ),
                    value: "",
                  },
                  ...timezoneList,
                ]}
                labelKey="label"
                valueKey={{ key: "value", reduce: true }}
              />
            </FormField>

            <FormField
              name="phoneNumber"
              htmlFor="phoneNumber"
              label={registration_phone_number_label}
              validate={formValues.phoneNumber ? phoneNumberValidation : ""}
            >
              <TextInput id="phoneNumber" name="phoneNumber" />
            </FormField>

            <Box
              pad={{ horizontal: "xxsmall" }}
              style={{ margin: "20px 0px 10px 0px" }}
            >
              <Text size="xlarge" weight="bold">
                {contact_preference}
              </Text>
              <Text size="small">{personalised_communications}</Text>
            </Box>

            <Box
              pad={{ horizontal: "xxsmall" }}
              direction="row"
              align="center"
              gap="large"
            >
              <Text
                size="medium"
                className={`contact-preferences-${currentStyle}`}
              >
                {email_id}
              </Text>
              <RadioButtonGroup
                id="emailContactPreference"
                name="emailContactPreference"
                options={[
                  { label: yes, value: "true" },
                  { label: no, value: "false" },
                ]}
                aria-labelledby="emailContactPreference"
                direction="row"
              />
            </Box>
            <Box
              pad={{ horizontal: "xxsmall" }}
              margin={{ top: "5px", bottom: "20px" }}
              direction="row"
              align="center"
              gap="large"
            >
              <Text
                size="medium"
                className={`contact-preferences-${currentStyle}`}
              >
                {phone}
              </Text>
              <RadioButtonGroup
                id="phoneContactPreference"
                name="phoneContactPreference"
                options={[
                  { label: yes, value: "true" },
                  { label: no, value: "false" },
                ]}
                aria-labelledby="phoneContactPreference"
                direction="row"
              />
            </Box>

            <FormField htmlFor="termsAndPrivacy">
              <CheckBox
                id="termsAndPrivacy"
                name="termsAndPrivacy"
                required
                align="center"
                label={
                  <Box alignContent="start">
                    <Text weight={"normal"} size="small">
                      {privacy_policy_accept}{" "}
                      <Anchor
                        label={hpe_terms_of_use}
                        href={hpeTermsOfUseUrl(
                          filterLanguageAndCountryCode(
                            supportedLanguagesListDetails,
                            currentStyle
                          )
                        )}
                        target="_blank"
                        rel="noreferrer noopener"
                        color="rgba(23,235,160,1)"
                      />{" "}
                      {chinaCountryCheck(formValues.selectCountry)
                        ? privacy_policy_china
                        : privacy_policy_other_countries}
                      <Anchor
                        label={hpe_privacy_notice}
                        href={hpePrivacyNoticeUrl(
                          filterLanguageAndCountryCode(
                            supportedLanguagesListDetails,
                            currentStyle
                          )
                        )}
                        target="_blank"
                        rel="noreferrer noopener"
                        color="rgba(23,235,160,1)"
                      />
                      .*
                    </Text>
                  </Box>
                }
              />
              {chinaCountryCheck(formValues.selectCountry) && (
                <CheckBox
                  id="termsAndPrivacyChina"
                  name="termsAndPrivacyChina"
                  required
                  align="center"
                  label={
                    <Box alignContent="start">
                      <Text weight={"normal"} size="small">
                        {china_policy_terms_1}
                        <Anchor
                          label={china_policy_terms_2}
                          href={getChinaPolicyLink(
                            filterLanguageAndCountryCode(
                              supportedLanguagesListDetails,
                              currentStyle
                            )
                          )}
                          target="_blank"
                          rel="noreferrer noopener"
                          color="rgba(23,235,160,1)"
                        />{" "}
                        {china_policy_terms_3}*
                      </Text>
                    </Box>
                  }
                />
              )}
            </FormField>
            <Text size="small">{required_field_1}</Text>
          </Box>
          <Box align="center" gap="medium">
            <Button
              label={create_account}
              // fill="horizantal"
              fill="horizantal"
              primary
              type="submit"
              className="submit-btn"
              style={{ marginTop: "20px", padding: "8px 20px" }}
              disabled={showSpinner ? true : false}
            />
            {showSpinner && <Spinner />}
          </Box>
          {formError && (
            <Box
              margin={{ top: "medium", bottom: "medium" }}
              round="4px"
              pad="small"
              background="validation-critical"
              direction="row"
              gap="xsmall"
            >
              <Box
                flex={false}
                margin={{ top: "hair" }}
                pad={{ top: "xxsmall" }}
              >
                <CircleAlert size="small" />
              </Box>
              <Text size="xsmall">{errMessage}</Text>
            </Box>
          )}
        </Form>

        <Box
          pad={{ horizontal: "xxsmall" }}
          margin={{ top: "20px", bottom: "30px" }}
        >
          <Button onClick={() => backToSignInHandler()} size="xxxsmall">
            <u>{back_to_sign_in}</u>
          </Button>
        </Box>
      </Box>
    </Grommet>
  );
};

export default Signup;
